import React, { useState, useEffect} from "react"
import { createTheme , ThemeProvider } from '@mui/material/styles';
import "@mui/material"
//import NavBar from "./NavBar.js";
import NavBarCustom from "./NavBarCustom.js";
import Sidebar from "./Sidebar.js";
import SidebarExperimental from "./SidebarExperimental.js";
import MainContent from "./MainContent.js"
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import {AuthProvider} from "./utils/Auth";
import MeetingCTABanner from "./utils/MeetingCTABanner"
import ProductHelp from "./utils/ProductHelp"
import fire, {getanalytics} from "./utils/base";
//import {Grid} from '@mui/material';
import Onboarding from "./Onboarding"

import store from './store/store.js'
import { Provider, useSelector, useDispatch } from 'react-redux'
import {setRoleValue, setEmailValue , setNameValue , setReportValue, setAccountValue} from './store/roleSlice';

import axios from "axios";
import {BACKEND_ADDRESS} from "./utils/getEndpoint.js"
import LoginContent from "./login/LoginContent.js"
import Dashboard from "./dashboard/Dashboard.js";

const theme = createTheme({
  palette: {
    primary: {
      main: "#395af6",
      light: "#7e93f9",
      dark: "#273eac"
    }
  },
  button: {
    borderColor: "chartreuse !important;",
  },
  typography: {
    color: 'black'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
})

function GetApp() {
  let history = useHistory();
  const [onboarded, setOnboarded] = useState(null);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [isLoggedIn, setIsLoggedIn] = useState(false) //sets isLoggedIn false so that authentication is needed again whenever getApp is opened
  const role = useSelector((state) => state.role.value)
  const dispatch = useDispatch()

  useEffect(() => {
    if(email === "" || role !== "") return

    //get role and onboarding status and store it in redux
    axios.post(BACKEND_ADDRESS + '/user/user_by_email/', {'email': email })
    .then((response) => {
      var allmuboards = response.data;
      console.log(email)

      if(allmuboards.length > 1){
        console.log("Multiple users found in db while authenticating")
        history.push('/signin')
      }else if(allmuboards.length === 0 || allmuboards[0].is_active === false){
        console.log("user not found in db. Setting role as guest")
        history.push('/signin')
      }else{
        //logging event in firebase
        getanalytics.logEvent("product_opened", {email: email})

        setOnboarded(allmuboards[0].onboarding);
        dispatch(setEmailValue(email));
        dispatch(setRoleValue(allmuboards[0].role));
        dispatch(setAccountValue(allmuboards[0].account_id))
        dispatch(setNameValue(allmuboards[0].name));
        dispatch(setReportValue(allmuboards[0].reports));
        console.log(email)
        // window.LOU.identify(email, {
        //   company: allmuboards[0].account_id, // Replace this object with any user data for defining segments
        //   permissions: 'user', //admin/,manager/user
        //   plan: 'free', //free/paid
        // })
      }
    })
  }, [email, role])

  fire.auth().onAuthStateChanged((user)=>{
    if(isLoggedIn) return
    const now = new Date();
    const expiry_time=now.getTime()-30*24*3600*1000;

    if(user!==null && (user.metadata.b > expiry_time )){
      setEmail(user.email)
      setName(user.displayName)
      setIsLoggedIn(true)
    }else{
      setIsLoggedIn(false)
      history.push('/signin')
    }
  });

  return  (
    <div style={{display: 'flex'}} direction="column">
      {onboarded !== null?
        <>
          <NavBarCustom />
          <SidebarExperimental />
          <MainContent/>
          {/*<MeetingCTABanner />
          */}
          <ProductHelp />
          {onboarded === false?<Onboarding setOnboarded={setOnboarded}/>:<></>}
        </>
        :<></>
      }
    </div>
  );
}

function App() {
  const next_path = window.location.pathname

  return(
    <Provider store={store}>
      <AuthProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/signin" component={()=> <ThemeProvider theme={theme}><LoginContent next_path = {next_path}/></ThemeProvider>} />
            <Route path="/signup" component={()=> <ThemeProvider theme={theme}><LoginContent next_path = {next_path}/></ThemeProvider>} />
            <Route path="/dashboard/:a_id" render={(props)=> <ThemeProvider theme={theme}><Dashboard mode="view" {...props} /></ThemeProvider>} />
            <Route path="/" component={()=> <ThemeProvider theme={theme}><GetApp /></ThemeProvider>} />
          </Switch>
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  );
}

export default App;
