import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Typography, InputAdornment, ListSubheader} from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import PhoneIcon from '@mui/icons-material/Phone';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
import ToastComponent from "./../utils/ToastComponent";
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { PERSONAS, STAGE_MAPPING } from "./../utils/constants"
import TipBox from "./../utils/TipBox"

import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const getActiveIndex = (metadata, customerProfiles) => {
  var ans = {
    //to be replaced by index number of selected option
    activeCp: "",
    activePersona: "",
    activeIndustry: "",
    activeVertical: "",
    activeGeography: "",
    activeCompanySize: "",
    activeTeamSize: "",
    activeBuyingSignalsTech: "",
    activeBuyingSignalsNonTech: ""
  }

  if(metadata["cp"] !== ""){
    for(var i=0;i<customerProfiles.length;i+=1){
      if(customerProfiles[i]["_id"]["$oid"] === metadata["cp"]["id"]){
        ans["activeCp"] = i
        break
      }
    }
  }

  if(ans["activeCp"] === -1) return ans

  if(metadata["persona"] !== ""){
    for(i=0;i<customerProfiles[ans["activeCp"]]["personas"].length;i+=1){
      if(customerProfiles[ans["activeCp"]]["personas"][i]["_id"]["$oid"] === metadata["persona"]["id"]){
        ans["activePersona"] = i
        break
      }
    }
  }

  if(metadata["industry"] !== ""){
    for(i=0;i<customerProfiles[ans["activeCp"]]["industries"].length;i+=1){
      if(customerProfiles[ans["activeCp"]]["industries"][i] === metadata["industry"]){
        ans["activeIndustry"] = i
        break
      }
    }
  }

  if(metadata["vertical"] !== ""){
    for(i=0;i<customerProfiles[ans["activeCp"]]["verticals"].length;i+=1){
      if(customerProfiles[ans["activeCp"]]["verticals"][i] === metadata["vertical"]){
        ans["activeVertical"] = i
        break
      }
    }
  }

  if(metadata["geography"] !== ""){
    for(i=0;i<customerProfiles[ans["activeCp"]]["geographies"].length;i+=1){
      if(customerProfiles[ans["activeCp"]]["geographies"][i] === metadata["geography"]){
        ans["activeGeography"] = i
        break
      }
    }
  }

  if(metadata["company_size"] !== ""){
    for(i=0;i<customerProfiles[ans["activeCp"]]["company_size"].length;i+=1){
      if(customerProfiles[ans["activeCp"]]["company_size"][i] === metadata["company_size"]){
        ans["activeCompanySize"] = i
        break
      }
    }
  }

  if(metadata["team_size"] !== ""){
    for(i=0;i<customerProfiles[ans["activeCp"]]["team_size"].length;i+=1){
      if(customerProfiles[ans["activeCp"]]["team_size"][i] === metadata["team_size"]){
        ans["activeTeamSize"] = i
        break
      }
    }
  }

  if(metadata["buying_signals_tech"] !== ""){
    for(i=0;i<customerProfiles[ans["activeCp"]]["buying_signals_tech"].length;i+=1){
      if(customerProfiles[ans["activeCp"]]["buying_signals_tech"][i] === metadata["buying_signals_tech"]){
        ans["activeBuyingSignalsTech"] = i
        break
      }
    }
  }

  if(metadata["buying_signals_non_tech"] !== ""){
    for(i=0;i<customerProfiles[ans["activeCp"]]["buying_signals_non_tech"].length;i+=1){
      if(customerProfiles[ans["activeCp"]]["buying_signals_non_tech"][i] === metadata["buying_signals_non_tech"]){
        ans["activeBuyingSignalsNonTech"] = i
        break
      }
    }
  }

  return ans
}

export default function ModifySingleLead(props){
  const [leadData, setLeadData] = useState({})
  const [activeFields, setActiveFields] = useState({activeCp: ""})
  const [customerProfiles, setCustomerProfiles] = useState([])
  const [errorMessage, setErrorMessage] = useState("")

  const account_id = useSelector(state => state.account.value)

  useEffect(() => {
    axios.get(BACKEND_ADDRESS + '/cp/all_cps/', {params: {account_id: account_id}})
    .then((response) => {
      setCustomerProfiles(response.data["customer_profiles"])

      const keys = props.data[props.leadListNo]["fields"]
      var leadDataInitial = {metadata: {}}

      if(props.mode === "edit"){
        for (var i=0;i<keys.length;i+=1){
          leadDataInitial[keys[i]] = props.data[props.leadListNo]["data"][keys[i]][props.leadNo]
        }

        leadDataInitial["metadata"]["cp"] = props.data[props.leadListNo]["metadata"]["cp"][props.leadNo]
        leadDataInitial["metadata"]["persona"] = props.data[props.leadListNo]["metadata"]["persona"][props.leadNo]
        leadDataInitial["metadata"]["industry"] = props.data[props.leadListNo]["metadata"]["industry"][props.leadNo]
        leadDataInitial["metadata"]["vertical"] = props.data[props.leadListNo]["metadata"]["vertical"][props.leadNo]
        leadDataInitial["metadata"]["geography"] = props.data[props.leadListNo]["metadata"]["geography"][props.leadNo]
        leadDataInitial["metadata"]["company_size"] = props.data[props.leadListNo]["metadata"]["company_size"][props.leadNo]
        leadDataInitial["metadata"]["team_size"] = props.data[props.leadListNo]["metadata"]["team_size"][props.leadNo]
        leadDataInitial["metadata"]["buying_signals_tech"] = props.data[props.leadListNo]["metadata"]["buying_signals_tech"][props.leadNo]
        leadDataInitial["metadata"]["buying_signals_non_tech"] = props.data[props.leadListNo]["metadata"]["buying_signals_non_tech"][props.leadNo]

        setActiveFields(getActiveIndex(leadDataInitial["metadata"], response.data["customer_profiles"]))
      }else{
        for (var i=0;i<keys.length;i+=1){
          leadDataInitial[keys[i]] = ""
        }

        leadDataInitial["metadata"]["cp"] = ""
        leadDataInitial["metadata"]["persona"] = ""
        leadDataInitial["metadata"]["industry"] = ""
        leadDataInitial["metadata"]["vertical"] = ""
        leadDataInitial["metadata"]["geography"] = ""
        leadDataInitial["metadata"]["company_size"] = ""
        leadDataInitial["metadata"]["team_size"] = ""
        leadDataInitial["metadata"]["buying_signals_tech"] = ""
        leadDataInitial["metadata"]["buying_signals_non_tech"] = ""

        setActiveFields(getActiveIndex(leadDataInitial["metadata"], response.data["customer_profiles"]))
      }

      //intialize
      setLeadData(leadDataInitial)
    })
  }, [])

  const updateLead = (key, val) => {
    var lead = leadData
    lead[key] = val
    setLeadData(lead)
  }

  const updateLeadSelect = (key1, key2, key3, ind) => {
    var lead = leadData
    var activeFieldsCopy = JSON.parse(JSON.stringify(activeFields)) //deepcopy

    if(key1 === "cp"){
      lead["metadata"][key1] = customerProfiles[ind]

      //set persona
      if(customerProfiles[ind]["personas"].length === 0){
        lead["metadata"]["persona"] = ""
        activeFieldsCopy["activePersona"] = ""
      }else{
        lead["metadata"]["persona"] = customerProfiles[ind]["personas"][0]
        activeFieldsCopy["activePersona"] = 0
      }

      //initialise with first possible value for attributes
      for(var i=0;i<fieldsPlural.length;i+=1){
        if(customerProfiles[ind][fieldsPlural[i]].length === 0){
          lead["metadata"][fieldsSingular[i]] = ""
          activeFieldsCopy[fieldsActiveKeys[i]] = ""
        }else{
          lead["metadata"][fieldsSingular[i]] = customerProfiles[ind][fieldsPlural[i]][0]
          activeFieldsCopy[fieldsActiveKeys[i]] = 0
        }
      }
    }
    else lead["metadata"][key1] = customerProfiles[activeFields["activeCp"]][key2][ind]
    setLeadData(lead)

    activeFieldsCopy[key3] = ind
    setActiveFields(activeFieldsCopy)
  }

  const validateAndSubmit = () => {
    if(leadData["Name"] === ""){
      setErrorMessage("Name can't be blank")
      return
    }
    if(leadData["Email"] === "" && leadData["LinkedIn"] === "" && leadData["Twitter"] === ""&& leadData["Phone"] === ""){
      // TODO: better validation to be implemented
      setErrorMessage("At least one of Email, LinkedIn, Twitter, or Phone must not be blank!")
      return
    }

    //valid. Proceed
    setErrorMessage("")
    props.callback(leadData)
  }

  const fieldsName = ["Industry", "Vertical", "Geography", "Company Size", "Team Size", "Technical Buying Signal", "Non-technical Buying Signal"]
  const fieldsSingular = ["industry", "vertical", "geography", "company_size", "team_size", "buying_signals_tech", "buying_signals_non_tech"]
  const fieldsPlural = ["industries", "verticals", "geographies", "company_size", "team_size", "buying_signals_tech", "buying_signals_non_tech"]
  const fieldsActiveKeys = ["activeIndustry", "activeVertical", "activeGeography", "activeCompanySize", "activeTeamSize", "activeBuyingSignalsTech", "activeBuyingSignalsNonTech"]

  return (
    <>
    {
      Object.keys(leadData).length > 0?
        <Dialog open={true} fullWidth={true} maxWidth="sm">
          <DialogTitle >
            {props.mode === "add"?"Add Lead":"Edit Lead"}
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={1} >
              {props.data[props.leadListNo]["fields"].map((x, ind) =>
                  <TextField key={ind} required={x==="Name"} label={x} onChange={(e) => updateLead(x, e.target.value)}
                  defaultValue={leadData[x]} variant="standard" fullWidth InputProps={{
                  startAdornment: (
                  <InputAdornment position="start">
                    {x==="Email"?<EmailIcon />:x==="LinkedIn"?<LinkedInIcon />:x==="Twitter"?<TwitterIcon />:x==="Phone"?<PhoneIcon />:<></>}
                  </InputAdornment>
                  )}} />
              )}

              {errorMessage !== ""?<><Typography variant="subtitle2" sx={{color:"red", mb: 1}}>{errorMessage}</Typography></>:<></>}

              <br/><Typography><b>Customer Profile Tags</b></Typography>
              <FormControl  variant="standard" fullWidth>
                <InputLabel>Customer Profile</InputLabel>
                <Select variant="standard" value={activeFields["activeCp"]} onChange={(e) => updateLeadSelect("cp", "", "activeCp", e.target.value)}>
                  {customerProfiles.length === 0?
                    <Typography variant="subtitle2" style={{marginLeft: 12}}><i>You haven't created any Customer Profile yet</i></Typography>
                    :
                    customerProfiles.map((x, ind) =>
                      <MenuItem value={ind} key={ind}>{x["name"]}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
              {
                activeFields["activeCp"] !== ""?
                <>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Persona</InputLabel>
                    <Select variant="standard" value={activeFields["activePersona"]} onChange={(e) => updateLeadSelect("persona", "personas", "activePersona", e.target.value)}>
                      {customerProfiles[activeFields["activeCp"]]["personas"].length === 0?
                        <Typography variant="subtitle2" style={{marginLeft: 12}}><i>You haven't created any persona for this CP yet</i></Typography>
                        :
                        customerProfiles[activeFields["activeCp"]]["personas"].map((x, ind) =>
                          <MenuItem key={ind} value={ind}>{x["designation"] + " at " + x["vertical"]}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>

                  <Grid container direction="row" justifyContent="space-between" spacing={1} style={{marginLeft: -8}}>
                  {fieldsName.map((x, ind) =>
                      <Grid item xs={6} key={ind}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel>{fieldsName[ind]}</InputLabel>
                          <Select variant="standard" value={activeFields[fieldsActiveKeys[ind]]} onChange={(e) => updateLeadSelect(fieldsSingular[ind], fieldsPlural[ind], fieldsActiveKeys[ind], e.target.value)}>
                            {customerProfiles[activeFields["activeCp"]][fieldsPlural[ind]].length === 0?
                              <Typography variant="subtitle2" style={{marginLeft: 12}}><i>No values found for {fieldsName[ind]}</i></Typography>
                              :
                              customerProfiles[activeFields["activeCp"]][fieldsPlural[ind]].map((x, ind) =>
                                <MenuItem value={ind} key={ind}>{x}</MenuItem>
                              )
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                  )}
                  </Grid>
                </>
                :<></>
              }
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => props.callback({})}>Cancel</Button>
            <Button variant="contained" autoFocus onClick={validateAndSubmit}>{props.mode === "add"?"Create Lead":"Save Lead"}</Button>
          </DialogActions>
        </Dialog>
      :<></>
    }
    </>
  )
}
