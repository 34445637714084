import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Typography} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { PERSONAS } from "./../utils/constants"

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import ToastComponent from "./../utils/ToastComponent";
import TipBox from "./../utils/TipBox"

export default function AddPersona(props){
  var startPersona = {
    avatar: -1,
    designation: "",
    vertical: "",
    //problems: ["", "", ""],
    value_props: ["", "", ""],
    benefits: ["", "", ""],
    objections: [],
    usecases: []
  }
  if(props.mode == "edit"){
    for(var i=0;i<props.customerProfile["personas"].length;i+=1){
      if(props.customerProfile["personas"][i]["_id"]["$oid"] === props.p_id){
        startPersona = props.customerProfile["personas"][i]
      }
    }
  }

  const [step, setStep] = useState(1)
  const [avatar, setAvatar] = useState(startPersona["avatar"])
  const [designation, setDesignation] = useState(startPersona["designation"])
  const [vertical, setVertical] = useState(startPersona["vertical"])
  //const [problems, setProblems] = useState(startPersona["problems"])
  const [valueProps, setValueProps] = useState(startPersona["value_props"])
  const [benefits, setBenefits] = useState(startPersona["benefits"])
  const [objections, setObjections] = useState(startPersona["objections"])
  const [usecases, setUsecases] = useState(startPersona["usecases"])

  const [avatarError, setAvatarError] = useState(false)
  const [designationError, setDesignationError] = useState(false)
  //const [problemsError, setProblemsError] = useState(false)
  const [valuePropsError, setValuePropsError] = useState(false)
  const [benefitsError, setBenefitsError] = useState(false)

  const customerProfile = props.customerProfile
  const maxSteps = (customerProfile["stage"] === "Problem Validation")?2:3
  let history = useHistory();

  const validateEntry = () => {
    var flag = true

    if(step === 1){
      if(avatar === -1){
        flag = false
        setAvatarError(true)
      }else{
        setAvatarError(false)
      }

      if(designation === "" || vertical === ""){
        flag = false
        setDesignationError(true)
      }else{
        setDesignationError(false)
      }
    // }else if(step === 2){
      // var count = 0
      // var probs = []
      // for(var i=0;i<problems.length;i+=1){
      //   if(problems[i] !== ""){
      //     probs.push(problems[i])
      //   }
      // }
      // setProblems(probs)
      //
      // if(probs.length < 2){
      //   flag = false
      //   setProblemsError(true)
      // }else{
      //   setProblemsError(false)
      // }
    }else if(step === 2){
      var count = 0
      var probs = []
      for(var i=0;i<valueProps.length;i+=1){
        if(valueProps[i] !== ""){
          probs.push(valueProps[i])
        }
      }
      setValueProps(probs)
      console.log(probs)

      if(probs.length < 1){
        flag = false
        setValuePropsError(true)
      }else{
        setValuePropsError(false)
      }

      count = 0
      probs = []
      for(var i=0;i<benefits.length;i+=1){
        if(benefits[i] !== ""){
          probs.push(benefits[i])
        }
      }
      setBenefits(probs)

      if(probs.length < 1){
        flag = false
        setBenefitsError(true)
      }else{
        setBenefitsError(false)
      }
    } else if(step === 3){

    }

    if(flag){
      if(step === maxSteps){
        var newPersona = {
          customer_profile_id: customerProfile["_id"]["$oid"],
          avatar: avatar,
          designation: designation,
          vertical: vertical,
          value_props: valueProps,
          benefits: benefits,
          objections: objections,
          usecases: usecases,
          is_active: true
        }

        if(props.mode === "add"){
          axios.post(BACKEND_ADDRESS + '/persona/', newPersona)
          .then(response => {
            ToastComponent("info", "New Persona " + designation + ", " + vertical + " created!")
            history.push('/icp/' + String(customerProfile["_id"]["$oid"]))
          }).catch(error => ToastComponent("error", "Can't create Persona"))
        }else if(props.mode === "edit"){
          newPersona["id"] = startPersona["_id"]["$oid"]

          axios.put(BACKEND_ADDRESS + '/persona/', newPersona)
          .then(response => {
            ToastComponent("info", "Persona " + designation + ", " + vertical + " updated!")
            history.push('/icp/' + String(customerProfile["_id"]["$oid"]))
          }).catch(error => ToastComponent("error", "Can't update Persona"))
        }
      }
      else setStep(step + 1)
    }
  }

  // const updateProblems = (e, ind) => {
  //   var problemCopy = problems
  //   problemCopy[ind] = e.target.value
  //   setProblems(problemCopy)
  // }

  const updateValueProps = (e, ind) => {
    var valuePropsCopy = valueProps
    valuePropsCopy[ind] = e.target.value
    setValueProps(valuePropsCopy)
  }

  const updateBenefits = (e, ind) => {
    var benefitsCopy = benefits
    benefitsCopy[ind] = e.target.value
    setBenefits(benefitsCopy)
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10} lg={8} container direction="column" spacing={2}>
        <Grid item>
          <Paper style={{borderRadius: "8px", padding: "24px", minHeight: "500px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <Grid item>
              {step === 1?
                <>
                  <Typography>1. Choose an Avatar</Typography>
                  <br />
                  <Grid container direction="row">
                    {PERSONAS.map((x, ind) => {
                      return (
                        <Grid key={ind} item>
                          <IconButton sx={avatar===ind?{backgroundColor: "#90ee90", "&:hover": {backgroundColor: "#e6ffe6"}}:{}} onClick={() => setAvatar(ind)}><Avatar src={x} sx={{width: "80px", height: "80px", border: "10px"}}/></IconButton>
                        </Grid>
                      )
                    })}
                  </Grid>
                  <Typography variant="subtitle2" style={{color:"#d32f2f"}}>{avatarError?"Choose at least one avatar":<br />}</Typography>
                  <br /><br />
                  <Typography>2. Specify Persona Role</Typography>
                  <br />
                  <TextField placeholder="Vice President" required onChange={(e) => setDesignation(e.target.value)}
                  error={designationError} helperText={designationError?"Persona Role can't be blank":""} defaultValue={designation}
                  variant="standard" required />
                  &nbsp;&nbsp;at&nbsp;&nbsp;
                  <Select variant="standard" value={vertical} onChange={(e) => setVertical(e.target.value)} sx={{minWidth: 150}}>
                    {customerProfile["verticals"].map((x, ind) => {
                      return (
                        <MenuItem value={x}>{x}</MenuItem>
                      )
                    })}
                  </Select>
                </>
              :step === 2?
                <>
                  <TipBox tip={
                    <Typography fontSize="15">
                      <b>Value Proposition</b> is the big-picture statement that addresses the most essential question in a buyer's mind: "What's in it for me?"<br /><br />
                      <b>Example- Value Props of Zoom for Sales Manager persona</b><br />
                      <i><u>Qualitative Value Props</u></i><br />
                      Zoom Pro offers central call recordings hosted on our cloud on a team account.<br />
                      Deal intelligence data based on call transcriptions leading to more sales.<br />
                      <br />
                      <i><u>Quantitative Value Props</u></i><br />
                      With Zoom Pro you can have calls up to 5 hours which typically exceed a sales call length.<br />
                      Private Call recordings with 99.99% guarantee of Uptime.<br />
                    </Typography>
                  }/>
                  <br />
                  <Typography>3. Qualitative Value Props (at least 1) that you are offering to the persona</Typography>
                  <br />
                  <TextField key={11} placeholder="Compulsory*" required onChange={(e) => updateValueProps(e, 0)}
                  defaultValue={valueProps[0]}
                  variant="standard" sx={{width: "100%"}} multiline/>
                  <TextField key={22} placeholder="Optional" required onChange={(e) => updateValueProps(e, 1)}
                  defaultValue={valueProps[1]}
                  variant="standard" sx={{width: "100%"}} multiline/>
                  <br />
                  <TextField key={33} placeholder="Optional" onChange={(e) => updateValueProps(e, 2)}
                  error={valuePropsError} helperText={valuePropsError?"Write down atleast 1 value prop":""} defaultValue={valueProps[2]}
                  variant="standard" sx={{width: "100%"}} multiline/>
                  <br /><br />

                  <Typography>4. Quantitative Value Props (at least 1) that you are offering to the persona</Typography>
                  <br />
                  <TextField placeholder="Compulsory*" required onChange={(e) => updateBenefits(e, 0)}
                  defaultValue={benefits[0]}
                  variant="standard" sx={{width: "100%"}} multiline/>
                  <TextField placeholder="Optional" required onChange={(e) => updateBenefits(e, 1)}
                  defaultValue={benefits[1]}
                  variant="standard" sx={{width: "100%"}} multiline/>
                  <br />
                  <TextField placeholder="Optional" onChange={(e) => updateBenefits(e, 2)}
                  error={benefitsError} helperText={benefitsError?"Write down at least 1 value prop":""} defaultValue={benefits[2]}
                  variant="standard" sx={{width: "100%"}} multiline/>
                  <br /><br />
                </>
              :<></>
              }
            </Grid>

            <Grid item container direction="row" justifyContent="space-between" spacing={1}>
              <Grid item xs={12}><Divider style={{marginTop: "12px"}}/></Grid>
              <Grid item>
                {step !== 1?
                  <Button variant="outlined" sx={{textTransform: 'none'}} onClick={() => {if(step > 1) setStep(step-1)}}>Back</Button>
                :<></>
                }
              </Grid>
              <Grid item>
                <Typography variant="h6">Step {step}/{maxSteps}</Typography>
              </Grid>
              <Grid item>
                {step === maxSteps?
                  <Button variant="contained" sx={{textTransform: 'none'}} onClick={validateEntry} autoFocus>{props.mode === "add"?"Submit":"Save Changes"}</Button>
                  :
                  <Button variant="contained" sx={{textTransform: 'none'}} onClick={validateEntry} autoFocus>Next</Button>
                }
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}
