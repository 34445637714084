import React from 'react';
import ForgotPassword from "./ForgotPassword.js"
import Signin from "./Signin.js"
import { Router, Route, Switch, useHistory } from "react-router-dom";

export default function LoginContent(props) {
  const history = useHistory();
  //let history = props.history;

  return (
    <Router history={history}>
      <Switch>
        <Route path="/signin/forgotpassword" component={ForgotPassword}/>
        <Route path="/signin" component={()=><Signin next_path={props.next_path} new_user={false}/>} />
        <Route path="/signup" component={()=><Signin next_path={props.next_path} new_user={true}/>} />
      </Switch>
    </Router>
  );
}
