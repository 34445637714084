import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Typography} from '@mui/material';

import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ToastComponent from "./../utils/ToastComponent";
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { PERSONAS, STAGE_MAPPING } from "./../utils/constants"

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField';

import VisibilityIcon from '@mui/icons-material/Visibility';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ListIcon from '@mui/icons-material/List';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CampaignContent from "./CampaignContent"
import CampaignAnalysis from "./CampaignAnalysis"
import AddCampaign from "./AddCampaign"

const updateCampaign = (allCampaigns, ind) => {
  axios.put(BACKEND_ADDRESS + '/campaign/', allCampaigns[ind])
  .then(response => ToastComponent("info", "Campaign " + allCampaigns[ind]["name"] + " paused"))
  .catch(error => ToastComponent("error", "Failed to complete action!"))
}

export default function Campaigns(props) {
  const account_id = useSelector((state) => state.account.value);
  const [allCampaigns, setAllCampaigns] = useState([])
  let history = useHistory()

  useEffect(() => {
    if(props.subMode === "all_campaigns"){
      props.callback("heading", "Campaigns")
      props.callback("description", "List of all campaigns that have been conducted")
    }else if(props.subMode === "campaign_content"){
      // props.callback("heading", "Campaigns")
      // props.callback("description", "")
    }else if(props.subMode === "add_campaign"){
      props.callback("heading", "Create Campaign")
      props.callback("description", "Create campaign for gathering market feedback")
    }

    window.scrollTo(0,0) //scrolling to top of new screen
  }, [props.subMode])

  useEffect(() => {
    axios.get(BACKEND_ADDRESS + '/campaign/all_campaigns/', {params: {account_id: account_id}})
    .then(response => setAllCampaigns(response.data["campaigns"].reverse()))
  }, [])

  const onPauseClick = (rowIndex) => {
    var allCampaignsCopy = allCampaigns.map((x, ind) => x) //deepcopy list
    allCampaignsCopy[rowIndex]["status"] = "Paused"
    setAllCampaigns(allCampaignsCopy)
    updateCampaign(allCampaignsCopy, rowIndex)
  }

  const onPlayClick = (rowIndex) => {
    var allCampaignsCopy = allCampaigns.map((x, ind) => x) //deepcopy list
    allCampaignsCopy[rowIndex]["status"] = "Active"
    setAllCampaigns(allCampaignsCopy)
    updateCampaign(allCampaignsCopy, rowIndex)
  }

  return (
    <>
    {props.subMode === "all_campaigns"?
      <>
        <div />
        <Typography>Run Campaign(s) to get market feedback from your Customer Profile</Typography>
        <Button autoFocus variant="contained" startIcon={<AddIcon />} onClick={() => history.push("/campaigns/add_new")}>Create New Campaign</Button>
        <div style={{marginTop: 30}} />
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead style={{backgroundColor: "#f5f5f5", height: "45px"}}>
              <TableRow>
                <TableCell><Typography><b>Campaign</b></Typography></TableCell>
                {/*<TableCell><Typography color="white">Sent</Typography></TableCell>
                <TableCell><Typography color="white">Replied</Typography></TableCell>
                <TableCell><Typography color="white">Meetings</Typography></TableCell>
                */}
                <TableCell align="right"><Typography><b>Status</b></Typography></TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {allCampaigns.length > 0?
              allCampaigns.map((x, rowIndex) => {
                return (
                  <TableRow key={rowIndex} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      <Typography variant="h6">{x["name"]}</Typography>
                      <Typography>{x["cp"]["name"]}</Typography>
                    </TableCell>
                    {/*
                    {Object.keys(x["performance"]).map((y, colIndex) => {
                      return (
                        <TableCell key={colIndex} component="th" scope="row">
                          <Typography variant="h6">{x["performance"][y]}</Typography>
                        </TableCell>
                      )
                    })}
                    */}
                    <TableCell sx={{'&:last-child td, &:last-child th': {border: 0}}} align="right">
                      <Typography variant="h6">{x["status"]}</Typography>
                    </TableCell>
                    <TableCell sx={{width: 300}}>
                      <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Stack direction="column" alignItems="center" sx={{width: "50"}}>
                          <IconButton onClick={() => history.push("/campaigns/" + x["_id"]["$oid"] + "/edit")}>
                            <VisibilityIcon />
                          </IconButton>
                          <Typography variant="subtitle2">View</Typography>
                        </Stack>
                        <Stack direction="column" alignItems="center" sx={{width: "50"}}>
                          {x["status"] === "Active"?
                            <>
                              <IconButton onClick={() => onPauseClick(rowIndex)}>
                                <PauseIcon />
                              </IconButton>
                              <Typography variant="subtitle2">Pause</Typography>
                            </>
                            :x["status"] === "Paused"?
                            <>
                              <IconButton onClick={() => onPlayClick(rowIndex)}>
                                <PlayArrowIcon />
                              </IconButton>
                              <Typography variant="subtitle2">Resume</Typography>
                            </>
                            :<></>
                          }
                        </Stack>
                        <Tooltip title="Campaign Dashboard">
                          <Stack direction="column" alignItems="center" sx={{width: "50"}}>
                            <IconButton onClick={() => history.push("/campaigns/" + x["_id"]["$oid"] + "/dashboard")}>
                              <AnalyticsIcon />
                            </IconButton>
                            <Typography variant="subtitle2">Analysis</Typography>
                          </Stack>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              })
              :
              <TableRow>
                <TableCell colSpan={3} rowSpan={3} align="center"><br /><br /><Typography>You haven't created any Campaigns yet.</Typography><br /><Button variant="outlined" onClick={() => history.push("/campaigns/add_new")}>Create your First Campaign Today!</Button><br /><br /><br /></TableCell>
              </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </>
    :props.subMode === "campaign_content" && allCampaigns.length > 0?
      <CampaignContent content={allCampaigns} {...props}/>
    :props.subMode === "campaign_analysis" && allCampaigns.length > 0?
      <CampaignAnalysis content={allCampaigns} {...props}/>
    :props.subMode === "add_campaign"?
      <AddCampaign mode="add"/>
    :
      <></>
    }
    </>
  )
}
