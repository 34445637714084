import { createSlice } from '@reduxjs/toolkit'

export const roleSlice = createSlice({
  name: 'role',
  initialState: {
    value: '',
  },
  reducers: {
    setRoleValue: (state, action) => {
      state.value = action.payload
    },
  },
})

export const emailSlice = createSlice({
  name: 'email',
  initialState: {
    value: '',
  },
  reducers: {
    setEmailValue: (state, action) => {
      state.value = action.payload
    },
  },
})

export const reportSlice = createSlice({
  name: 'reports',
  initialState: {
    value: '',
  },
  reducers: {
    setReportValue: (state, action) => {
      state.value = action.payload
    },
  },
})

export const nameSlice = createSlice({
  name: 'name',
  initialState: {
    value: '',
  },
  reducers: {
    setNameValue: (state, action) => {
      state.value = action.payload
    },
  },
})

export const avatarSlice = createSlice({
  name: 'name',
  initialState: {
    value: '',
  },
  reducers: {
    setAvatarValue: (state, action) => {
      state.value = action.payload
    },
  },
})

export const avatarInfoSlice = createSlice({
  name: 'name',
  initialState: {
    value: {},
  },
  reducers: {
    setAvatarInfoValue: (state, action) => {
      state.value = action.payload
    },
  },
})

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    value: '',
  },
  reducers: {
    setAccountValue: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setRoleValue} = roleSlice.actions
export const {setEmailValue} = emailSlice.actions
export const {setReportValue}= reportSlice.actions
export const {setNameValue}= nameSlice.actions
// export const {setAvatarValue} = avatarSlice.actions
// export const {setAvatarInfoValue} = avatarInfoSlice.actions
export const {setAccountValue} = accountSlice.actions

const roleReducer = roleSlice.reducer
const emailReducer = emailSlice.reducer
const reportReducer = reportSlice.reducer
const nameReducer = nameSlice.reducer
// const avatarReducer = avatarSlice.reducer
// const avatarInfoReducer = avatarInfoSlice.reducer
const accountReducer = accountSlice.reducer

// export {roleReducer, emailReducer , reportReducer , nameReducer, avatarReducer, avatarInfoReducer}
export {roleReducer, emailReducer , reportReducer , nameReducer, accountReducer}
