import { configureStore } from '@reduxjs/toolkit'
import {roleReducer, emailReducer , nameReducer , reportReducer, accountReducer} from './roleSlice'

export default configureStore({
  reducer: {
    role: roleReducer,
    email: emailReducer,
    name : nameReducer ,
    reports : reportReducer,
    // avatar : avatarReducer,
    // avatarInfo: avatarInfoReducer,
    account: accountReducer,
  },
})
