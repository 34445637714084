import React, { useState, useEffect } from 'react';
import {ListSubheader, Avatar, AccordionSummary, AccordionDetails, Typography, Box, IconButton, Button, Stack, Grid, Chip, Paper, TextField, Toolbar} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PendingIcon from '@mui/icons-material/Pending';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider'

import TipBox from "../utils/TipBox.js"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import LogCall from "./LogCall.js"
import ContactLead from "./ContactLead.js"
import FollowUp from './FollowUp.js';
import { BACKEND_ADDRESS } from '../utils/getEndpoint.js';
import axios from "axios"
import { Check } from '@mui/icons-material';
import { isValidEmail, isValidUrl } from '../utils/helper.js';

export default function ExecuteCampaign(props){
  const [activeCampaign, setActiveCampaign] = useState(props.activeCampaign)
  const [mode, setMode] = useState('none') //none/reachout/followup/log
  const [activeLead, setActiveLead] = useState(-1);
  const [activeList, setActiveList] = useState(-1);

  const callback = (campaign) => {
    setMode('none')
    setActiveList(-1)
    setActiveLead(-1)

    setActiveCampaign(campaign)
  }

  const setLeadStatus = (val, listNo, rowIndex) => {
    var obj = {type: val, status: "active"}
    if(val === "Contact Lead" || val === "Follow Up"){
      obj["subject"] = ""
      obj["message"] = ""
      obj["script"] = ""
    }

    if(val === "Contact Lead") setMode("reachout"); //open subtasks
    else if(val === "Follow Up") setMode("followup") //open subtasks
    else if(val === "Conversation Done") setMode("log") //open subtasks
    else obj["status"] = "completed" //end task

    setActiveLead(rowIndex); setActiveList(listNo);

    var newActiveCampaign = JSON.parse(JSON.stringify(activeCampaign))
    newActiveCampaign["lead_status"][listNo][rowIndex].push(obj)

    // update everywhere
    setActiveCampaign(newActiveCampaign)
    axios.put(BACKEND_ADDRESS + '/campaign/', activeCampaign)
  }

  const onLeadNotesChange = (val, listNo, rowIndex) => {
    var newActiveCampaign = JSON.parse(JSON.stringify(activeCampaign))
    newActiveCampaign["lead_notes"][listNo][rowIndex] = val

    // update everywhere
    setActiveCampaign(newActiveCampaign)
    axios.put(BACKEND_ADDRESS + '/campaign/', activeCampaign)
  }

  return (
    <TableContainer component={Paper}>
    {activeCampaign["leads"].map((leadList, listNo) => {
      return (
      <React.Fragment key={listNo}>
        {leadList["fields"].length > 0 && leadList["data"]["Email"].length > 0?
          <>
            <Table size="small">
              <TableHead >
                <TableRow sx={{backgroundColor: "#f5f5f5"}}>
                  <TableCell colSpan={5}><Typography>{leadList["name"]}</Typography></TableCell>
                </TableRow>
                <TableRow>
                  {["Name", "Contact", "Customer Profile Tags", "Task Cadence", "Notes"].map((x, ind)=> {
                    return (
                      <TableCell key={ind}>
                        <Typography style={x === "Sequence"?{marginLeft:"8px"}:{}}><b>{x}</b></Typography>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({length: leadList["data"]["Email"].length }).map((_, rowIndex) =>{
                  return (
                    <React.Fragment key={rowIndex}>
                    {activeCampaign["filtered_leads"][listNo][rowIndex]?
                    <TableRow key={rowIndex} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      <TableCell key="Name" component="th" scope="row">
                        {leadList["data"]["Name"][rowIndex]}
                      </TableCell>
                      <TableCell key="Contact" component="th" scope="row" sx={{maxWidth: 250}}>
                        {leadList["filteredFields"].includes("Email") && leadList["data"]["Email"][rowIndex] !== ""?
                          <>
                          {isValidEmail(leadList["data"]["Email"][rowIndex])?
                          <a href={"mailto:"+leadList["data"]["Email"][rowIndex]}>
                            <Chip avatar={<Avatar>{leadList["data"]["Email"][rowIndex][0]}</Avatar>}
                              variant="outlined" label={leadList["data"]["Email"][rowIndex]} />
                          </a>
                          :"Email: " + leadList["data"]["Email"][rowIndex]}
                          <br />
                          </>
                        :""}
                        {leadList["filteredFields"].includes("LinkedIn") && leadList["data"]["LinkedIn"][rowIndex] !== ""?
                          <>
                          {isValidUrl(leadList["data"]["LinkedIn"][rowIndex])?
                          <a target="_blank" href={leadList["data"]["LinkedIn"][rowIndex]}>
                            LinkedIn
                          </a>
                          :"LinkedIn: " + leadList["data"]["LinkedIn"][rowIndex]}
                          <br />
                          </>
                        :""}
                        {leadList["filteredFields"].includes("Twitter") && leadList["data"]["Twitter"][rowIndex] !== ""?
                          <>
                          {isValidUrl(leadList["data"]["Twitter"][rowIndex])?
                          <a target="_blank" href={leadList["data"]["Twitter"][rowIndex]}>
                            Twitter
                          </a>
                          :"Twitter: " + leadList["data"]["Twitter"][rowIndex]}
                          <br />
                          </>
                        :""}
                        {leadList["filteredFields"].includes("Phone") && leadList["data"]["Phone"][rowIndex] !== ""?<>{"Phone: " + leadList["data"]["Phone"][rowIndex]}<br /></>:""}
                      </TableCell>
                      <TableCell>
                        {leadList["metadata"]["cp"][rowIndex]["name"] !== ""?<>{"Customer Profile: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["cp"][rowIndex]["name"]} /><br /></>:<></>}
                        {leadList["metadata"]["persona"][rowIndex] && leadList["metadata"]["persona"][rowIndex]["designation"] !== ""?<>{"Persona: "}<Chip  sx={{mb: "1"}} size="small" label={leadList["metadata"]["persona"][rowIndex]["designation"] + " at " + leadList["metadata"]["persona"][rowIndex]["vertical"]} /><br /></>:<></>}
                        {leadList["metadata"]["industry"][rowIndex] !== ""?<>{"Industry: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["industry"][rowIndex]} />&nbsp;</>:<></>}
                        {leadList["metadata"]["vertical"][rowIndex] !== ""?<>{"Vertical: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["vertical"][rowIndex]} />&nbsp;</>:<></>}
                        {leadList["metadata"]["geography"][rowIndex] !== ""?<>{"Geography: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["geography"][rowIndex]} /><br /></>:<></>}
                        {leadList["metadata"]["company_size"][rowIndex] !== ""?<>{"Company Size: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["company_size"][rowIndex]} />&nbsp;</>:<></>}
                        {leadList["metadata"]["team_size"][rowIndex] !== ""?<>{"Team Size: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["team_size"][rowIndex]} /><br /></>:<></>}
                        {leadList["metadata"]["buying_signals_tech"][rowIndex] !== ""?<>{"Technical Buying Signal: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["buying_signals_tech"][rowIndex]} /><br /></>:<></>}
                        {leadList["metadata"]["buying_signals_non_tech"][rowIndex] !== ""?<>{"Non-technical Buying Signal: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["buying_signals_non_tech"][rowIndex]} /><br /></>:<></>}
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" alignItems="flex-end">
                          {activeCampaign["lead_status"][listNo][rowIndex].map((val, i) => {
                            return (
                              <React.Fragment key={i}>
                                {val["type"] === "New"?
                                  <></>
                                :val["type"] === "Contact Lead" || val["type"] === "Follow Up"?
                                  <Stack direction="row" alignItems="center">
                                    {val["status"] === "completed"?<CheckCircleIcon style={{color:"#3f8f29"}}/>:<></>}
                                    <Typography variant="subtitle2">{val["type"]}</Typography>
                                    {val["status"] === "active"?<IconButton sx={{p:0.5}} size="small" color="primary" onClick={() => {setMode(val["type"] === "Contact Lead"?"reachout":"followup"); setActiveLead(rowIndex); setActiveList(listNo)}}><EditIcon fontSize="small"/></IconButton>
                                    :<></>}
                                    {val["status"] === "completed"?<NavigateNextIcon />:<></>}
                                  </Stack>
                                :val["type"] === "No Reply" || val["type"] === "Replied, but Not Interested"?
                                  <Stack direction="row" alignItems="center">
                                    <CancelIcon style={{color:"#de1a24"}}/>
                                    <Typography variant="subtitle2">{val["type"]}</Typography>
                                  </Stack>
                                :val["type"] === "Conversation Done"?
                                  <Stack direction="row" alignItems="center">
                                    {val["status"] === "completed"?<CheckCircleIcon style={{color:"#3f8f29"}}/>:<PendingIcon style={{color: "#ffc232"}}/>}
                                    <Typography variant="subtitle2">Conversation Done</Typography>
                                    <IconButton sx={{p:0.5}} color="primary" onClick={() => {setMode("log"); setActiveLead(rowIndex); setActiveList(listNo)}}><EditIcon fontSize="small"/></IconButton>
                                  </Stack>
                                :<></>
                                }
                                {i === activeCampaign["lead_status"][listNo][rowIndex].length - 1 && val["status"] === "completed"?
                                  val["type"] === "New"?
                                    <Button size="small" onClick={() => {setLeadStatus("Contact Lead", listNo, rowIndex)}}>Contact Lead</Button>
                                  :val["type"] === "Contact Lead"?
                                    <FormControl>
                                      <InputLabel sx={{ml: -2}}>Next Task</InputLabel>
                                      <Select sx={{width: "80px"}} size="small" label='Next Task' variant="standard" onChange={e => setLeadStatus(e.target.value, listNo, rowIndex)}>
                                        <ListSubheader>Active</ListSubheader>
                                        <MenuItem value={"Follow Up"}>Follow Up</MenuItem>
                                        <ListSubheader>Mark as Closed</ListSubheader>
                                        <MenuItem value={"No Reply"}>No Reply</MenuItem>
                                        <MenuItem value={"Replied, but Not Interested"}>Replied, but Not Interested</MenuItem>
                                        <MenuItem value={"Conversation Done"}>Conversation Done</MenuItem>
                                      </Select>
                                    </FormControl>
                                  :val["type"] === "Follow Up"?
                                    <FormControl>
                                      <InputLabel sx={{ml: -2}}>Next Task</InputLabel>
                                      <Select sx={{width: "80px"}} size="small" autoFocus placeholder='Next Task' variant="standard" onChange={e => setLeadStatus(e.target.value, listNo, rowIndex)}>
                                        <ListSubheader>Mark as Closed</ListSubheader>
                                        <MenuItem value={"No Reply"}>No Reply</MenuItem>
                                        <MenuItem value={"Replied, but Not Interested"}>Replied, but Not Interested</MenuItem>
                                        <MenuItem value={"Conversation Done"}>Conversation Done</MenuItem>
                                      </Select>
                                    </FormControl>
                                  :<></>
                                :<></>
                                }
                              </React.Fragment>
                            )
                          })}
                        </Stack>
                      </TableCell>
                      <TableCell sx={{minWidth: "200px"}}>
                        <TextField variant="outlined" size="small" value={activeCampaign["lead_notes"][listNo][rowIndex]} onChange={e => onLeadNotesChange(e.target.value, listNo, rowIndex)} multiline minRows={3} fullWidth />
                      </TableCell>
                    </TableRow>
                    :<></>
                    }
                    </React.Fragment>
                  )
                })}
              </TableBody>
            </Table>

          {activeLead > -1 && activeList > -1?
            (mode === "log" && activeCampaign["lead_status"][activeList][activeLead].slice(-1)[0]["type"] === "Conversation Done"?
              <LogCall mode={mode} campaign={activeCampaign} listNo={activeList} leadNo={activeLead} cp={activeCampaign["cp"]} callback={callback}/>
            :mode === "reachout" && activeCampaign["lead_status"][activeList][activeLead].slice(-1)[0]["type"] === "Contact Lead"?
              <ContactLead campaign={activeCampaign} listNo={activeList} leadNo={activeLead} cp={activeCampaign["cp"]} callback={callback} />
            :mode === "followup" && activeCampaign["lead_status"][activeList][activeLead].slice(-1)[0]["type"] === "Follow Up"?
              <FollowUp campaign={activeCampaign} listNo={activeList} leadNo={activeLead} cp={activeCampaign["cp"]} callback={callback} />
            :<></>)
          :<></>
          }
          </>
          :<></>
        }
      </React.Fragment>
      )
    })}
    </TableContainer>
  )
}
