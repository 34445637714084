import React, { useState, useEffect, useRef } from 'react';
import {Skeleton, ToggleButton, ToggleButtonGroup, FormHelperText, Accordion, AccordionSummary, AccordionDetails, Typography, Box, IconButton, Button, Stack, Grid, Chip, Paper, TextField, Toolbar, Radio, Checkbox, RadioGroup} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider'

import TipBox from "./../utils/TipBox"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { useSelector } from 'react-redux'
import ToastComponent from '../utils/ToastComponent';

const allChannels = ["Email/LinkedIn InMail", "WhatsApp/LinkedIn/Twitter DM etc.", "Cold Call", "Face-to-face/At Events"]
const allCTAs = ["Video Call", "In Person"]

function replaceConditionalValues(str, key, x) {
  const regex = new RegExp(`\\[${key}\\?([^:]+):([^\\]]+)\\]`, 'g');

  return str.replace(regex, (_, val1, val2) => {
    return x ? val1 : val2;
  });
}

const HighlightText = ({ text }) => {
  // Regex to find all curly braces content
  const regex = /{([^}]+)}/g;
  const parts = text.split(regex);

  return (
    <Box>
      {parts.map((part, index) =>
        index % 2 === 1 ? (
          <Box component="span" key={index} sx={{ color: 'red' }}>
            {"{" + part + "}"}
          </Box>
        ) : (
          <Box component="span" key={index}>
            {part}
          </Box>
        )
      )}
    </Box>
  );
};

export default function ContactLead(props){
  var listNo = props.listNo
  var leadNo = props.leadNo
  var campaign = props.campaign
  var leadList = campaign["leads"][listNo]

  var allProblems = props.cp["problems"]
  var allIndustries = props.cp["industries"]
  var allVerticals = props.cp["verticals"]

  //Initialisation attempt 1: use defaults
  var initialObj = {
    //things that can be initialised from previously filled fields
    problem: allProblems[0],
    meetingLink: "",
    areas: "",
    cta: "Video Call"
  }
  //Initialisation attempt 2: use data from last filled lead
  for(var i=0;i<campaign["lead_status"].length;i+=1){
    for(var j=0;j<campaign["lead_status"][i].length; j+=1){
      if(campaign["lead_status"][i][j].length > 1 && campaign["lead_status"][i][j][1]["status"] === "completed"){
        initialObj = campaign["lead_status"][i][j][1]
        break
      }
    }
  }

  const sellerName = useSelector((state) => state.name.value);
  const account = useSelector(state => state.account.value)
  const hasProduct = campaign["hasProduct"]
  const [sellerCompany, setSellerCompany] = useState("")

  const [channel, setChannel] = useState("")
  const [name, setName] = useState(leadList["data"]["Name"][leadNo])
  const [problem, setProblem] = useState(initialObj["problem"])
  const [industry, setIndustry] = useState(leadList["metadata"]["industry"][leadNo])
  const [vertical, setVertical] = useState(leadList["metadata"]["vertical"][leadNo])
  const [cta, setCta] = useState(initialObj["cta"])
  const [meetingLink, setMeetingLink] = useState(initialObj["meetingLink"])
  const [areas, setAreas] = useState(initialObj["areas"])
  const [referenceName, setReferenceName] = useState("")

  const [channelError, setChannelError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [problemError, setProblemError] = useState(false)
  const [industryError, setIndustryError] = useState(false)
  const [verticalError, setVerticalError] = useState(false)
  const [ctaError, setCtaError] = useState(false)
  const [meetingLinkError, setMeetingLinkError] = useState(false)
  const [areasError, setAreasError] = useState(false)
  const [referenceNameError, setReferenceNameError] = useState(false)

  const [showCopy, setShowCopy] = useState(true)
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [script, setScript] = useState("")
  const [instruction, setInstruction] = useState("")

  useEffect(() => {
    axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account}})
    .then(response => setSellerCompany(response.data["company_name"]))
  }, [])

  const copyToClipboard = (sections) => {
    var flag = true

    //if(section === "message" || section === "script"){
      if(channel === ""){
        setChannelError(true)
        flag = false
      }else setChannelError(false)

      if(name === ""){
        setNameError(true)
        flag = false
      }else setNameError(false)

      if(problem === ""){
        setProblemError(true)
        flag = false
      }else setProblemError(false)

      if(industry === ""){
        setIndustryError(true)
        flag = false
      }else setIndustryError(false)

      if(vertical === ""){
        setVerticalError(true)
        flag = false
      }else setVerticalError(false)

      if(cta === ""){
        setCtaError(true)
        flag = false
      }else setCtaError(false)

      if(meetingLink === ""){
        setMeetingLinkError(true)
        flag = false
      }else setMeetingLinkError(false)

      if(areas === ""){
        setAreasError(true)
        flag = false
      }else setAreasError(false)

      if(channel === "WhatsApp/LinkedIn/Twitter DM etc."){
        if(referenceName === ""){
          setReferenceNameError(true)
          flag = false
        }else setReferenceNameError(false)
      }
    //}

    if(flag){
      var copyText = ""
      if(sections.includes("subject")) copyText += "Subject: " + subject + "\n\n"
      if(sections.includes("message")) copyText += "Message: " + message + "\n\n"
      if(sections.includes("script")) copyText += "Script: " + script + "\n\n"

      navigator.clipboard.writeText(copyText)
      ToastComponent("info", "Copied Successfully!")

      var obj = {
        type: "Contact Lead",
        status: "completed",
        subject: subject,
        message: message,
        script: script,
        channel: channel,

        //copy/script metadata
        problem: problem,
        cta: cta,
        meetingLink: meetingLink,
        areas: areas,
        referenceName: referenceName
      }

      var newActiveCampaign = JSON.parse(JSON.stringify(campaign))
      const statusLength = newActiveCampaign["lead_status"][listNo][leadNo].length
      newActiveCampaign["lead_status"][listNo][leadNo][statusLength - 1] = obj

      // store lead data labeled during campaign
      newActiveCampaign["leads"][listNo]["data"]["Name"][leadNo] = industry
      newActiveCampaign["leads"][listNo]["metadata"]["industry"][leadNo] = industry
      newActiveCampaign["leads"][listNo]["metadata"]["vertical"][leadNo] = vertical

      // update everywhere
      axios.put(BACKEND_ADDRESS + '/campaign/', newActiveCampaign)
      props.callback(newActiveCampaign) // in the very end
    }
    else ToastComponent("error", "Failed to copy as content to be copied still contains placeholders")
  }

  const updateContent = (field, copies) => {
    const sections = ["subject", "message", "script"]
    var newSectionValues

    if (copies === null) newSectionValues = [subject, message, script]
    else newSectionValues = copies

    for(var i =0;i<sections.length;i+=1){
      if(newSectionValues[i] !== ""){
        if(field === "name" || field === "all" && name!== "")newSectionValues[i] = newSectionValues[i].replaceAll("{name}", name)
        if(field === "problem" || field === "all" && problem !== "")newSectionValues[i] = newSectionValues[i].replaceAll("{problem}", problem)
        if(field === "industry" || field === "all" && industry !== "")newSectionValues[i] = newSectionValues[i].replaceAll("{industry}", industry)
        if(field === "vertical" || field === "all" && vertical !== "")newSectionValues[i] = newSectionValues[i].replaceAll("{vertical}", vertical)
        if(field === "cta" || field === "all" && cta !== "")newSectionValues[i] = replaceConditionalValues(newSectionValues[i], "meet_in_person", cta === "In Person")
        if(field === "meetingLink" || field === "all" && meetingLink !== "")newSectionValues[i] = newSectionValues[i].replaceAll("{meeting_link}", meetingLink)
        if(field === "areas" || field === "all" && areas !== "")newSectionValues[i] = newSectionValues[i].replaceAll("{add_areas}", areas)
        if(field === "referenceName" || field === "all" && referenceName !== "")newSectionValues[i] = newSectionValues[i].replaceAll("{friend_name}", referenceName)
      }

      if(field === "all"){
        newSectionValues[i] = newSectionValues[i].replaceAll("{seller_name}", sellerName)
        newSectionValues[i] = newSectionValues[i].replaceAll("{seller_company}", sellerCompany)
        newSectionValues[i] = replaceConditionalValues(newSectionValues[i], "has_product", hasProduct)
      }
    }

    setSubject(newSectionValues[0])
    setMessage(newSectionValues[1])
    setScript(newSectionValues[2])
  }

  useEffect(() => {
    var ind = 0
    for(var i =0;i<allChannels.length;i+=1){
      if(allChannels[i] === channel){
        ind = i
        break
      }
    }
    var obj = props.campaign["copy_data"][ind]["copy"][0]
    var newSubject, newMessage, newScript;

    if("subject" in obj) newSubject = obj["subject"]
    else newSubject = ""

    //substitute variables before setting state
    if("message" in obj) newMessage = obj["message"]
    else newMessage = ""
    if("script" in obj) newScript = obj["script"]
    else newScript = ""

    updateContent("all", [newSubject, newMessage, newScript])
  }, [channel, name, problem, industry, vertical, cta, meetingLink, areas, referenceName])

  // useEffect(() => updateContent("name", null), [name])
  // useEffect(() => updateContent("problem", null), [problem])
  // useEffect(() => updateContent("industry", null), [industry])
  // useEffect(() => updateContent("vertical", null), [vertical])
  // useEffect(() => updateContent("cta", null), [cta])
  // useEffect(() => updateContent("meetingLink", null), [meetingLink])
  // useEffect(() => updateContent("areas", null), [areas])
  // useEffect(() => updateContent("referenceName", null), [referenceName])

  const onPersonalise = (method) => {
    axios.post(BACKEND_ADDRESS + '/campaign/personalise_message/', {type: method, message: message, script: script})
    .then(response => {
      setMessage(response.data["message"])
      setScript(response.data["script"])
      //temporary
      ToastComponent("info", "Coming Soon!")
    }).catch(e => ToastComponent("error", "Can't personalise message/script"))
  }

  const onRewrite = () => {
    axios.post(BACKEND_ADDRESS + '/campaign/rewrite_message/', {instruction: instruction, message: message, script: script})
    .then(response => {
      setMessage(response.data["message"])
      setScript(response.data["script"])
      //temporary
      ToastComponent("info", "Coming Soon!")
    }).catch(e => ToastComponent("error", "Can't rewrite message/script"))
  }

  // const handleCopyDataChange = (a, b, c) => {
  // }

  return (
    <Dialog open={true} fullWidth maxWidth="lg" onClose={()=>props.callback(campaign)}>
      <DialogTitle >
        Contact Lead
      </DialogTitle>
      <DialogContent>
        <Paper elevation={0} sx={{width: "100%", overflowY: "auto", border: "1px solid rgba(0,0,0,0.12)", borderRadius: 2}}>
          <Grid container direction="row" spacing={2} >
            <Grid item xs={12} md={4}>
              <Paper square sx={{padding: "24 16", height: "100%", borderRight:"1px solid rgba(0, 0, 0, 0.12)", borderRadiusTopLeft: 2, borderRadiusBottomLeft: 2, backgroundColor: "#f5f5f5"}} elevation={0}>
                <Stack direction="column" spacing={1} sx={{mb:4}}>
                  <FormLabel sx={{color: "#395af6"}}>Copy/Script Settings</FormLabel>
                  <div style={{marginTop: "12"}}></div>
                  <FormControl sx={{width: "95%"}} required error={channelError}>
                    <InputLabel sx={{ml: -2}}>Platform/Channel</InputLabel>
                    <Select size="small" autoFocus required value={channel}
                    variant="standard" onChange={(e) => {setChannel(e.target.value); setShowCopy(true)}}>
                      {allChannels.map((x, ind) =>
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {channelError?<FormHelperText sx={{ml:0}}>Select a Platform/Channel</FormHelperText>:<></>}
                  </FormControl>

                  {channel === "WhatsApp/LinkedIn/Twitter DM etc."?
                  <FormControl sx={{width: "95%"}} error={referenceNameError}>
                    <TextField placeholder="Sam Altman" required size="small" label="Lead's Contact Shared by" value={referenceName}
                    variant="standard" onChange={(e) => {setReferenceName(e.target.value)}} />
                    {referenceNameError?<FormHelperText sx={{ml:0}}>Please fill a valid Name</FormHelperText>:<></>}
                  </FormControl>
                  :<></>}

                  <FormControl sx={{width: "95%"}} error={areasError}>
                    <TextField placeholder="" required size="small" label="Topic/area for which guidance is needed from lead" value={areas}
                    variant="standard" onChange={(e) => {setAreas(e.target.value)}}>
                      {areas}
                    </TextField>
                    {areasError?<FormHelperText sx={{ml:0}}>Please add a topic/area</FormHelperText>:<></>}
                  </FormControl>

                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography>Meeting CTA</Typography>
                    <ToggleButtonGroup size="small" exclusive color="primary" value={cta} onChange={(e, v) => {setCta(e.target.textContent)}}>
                      {allCTAs.map((x, ind) =>
                        <ToggleButton key={ind} aria-label={x} value={x} sx={{textTransform: 'none'}}>
                          <Typography>{x}</Typography>
                        </ToggleButton>
                      )}
                    </ToggleButtonGroup>
                  </Stack>

                  <FormControl sx={{width: "95%"}} error={meetingLinkError}>
                    <TextField required size="small" label="Meeting Link" value={meetingLink}
                    variant="standard" onChange={e => setMeetingLink(e.target.value)}>
                      {meetingLink}
                    </TextField>
                    {meetingLinkError?<FormHelperText sx={{ml:0}}>Please add a meeting link</FormHelperText>:<></>}
                  </FormControl>
                </Stack>

                <Stack direction="column" spacing={1}>
                  <FormLabel sx={{color: "#395af6"}}>Lead Details</FormLabel>

                  <FormControl sx={{width: "95%"}} error={nameError}>
                    <TextField placeholder="Satya Nadella" required size="small" label="Name" value={name}
                    variant="standard" onChange={e => setName(e.target.value)}>
                      {name}
                    </TextField>
                    {nameError?<FormHelperText sx={{ml:0}}>Please add lead name</FormHelperText>:<></>}
                  </FormControl>

                  <FormControl sx={{width: "95%"}} required error={problemError}>
                    <InputLabel sx={{ml: -2}}>Buyer Problem</InputLabel>
                    <Select size="small" value={problem}
                    variant="standard" onChange={(e) => setProblem(e.target.value)}>
                      {allProblems.map((x, ind) =>
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {problemError?<FormHelperText sx={{ml:0}}>Please select a buyer problem for this lead</FormHelperText>:<></>}
                  </FormControl>

                  <FormControl sx={{width: "95%"}} required error={industryError}>
                    <InputLabel sx={{ml: -2}}>Industry</InputLabel>
                    <Select size="small" value={industry}
                    variant="standard" onChange={(e) => setIndustry(e.target.value)}>
                      {allIndustries.map((x, ind) =>
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {industryError?<FormHelperText sx={{ml:0}}>Please select an industry</FormHelperText>:<></>}
                  </FormControl>

                  <FormControl sx={{width: "95%"}} required error={verticalError}>
                    <InputLabel sx={{ml: -2}}>Vertical</InputLabel>
                    <Select size="small" value={vertical}
                    variant="standard" onChange={(e) => setVertical(e.target.value)}>
                      {allVerticals.map((x, ind) =>
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {verticalError?<FormHelperText sx={{ml:0}}>Please select a vertical</FormHelperText>:<></>}
                  </FormControl>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper sx={{padding: "24 16", height: "100%"}} elevation={0}>
                {channel !== ""?
                  <Stack direction="column" sx={{width: "100%", flexWrap: 'wrap'}} spacing={1} >
                    <Stack direction="column" spacing={4} sx={{mt:0}}>
                      {subject !== ""?
                        <Typography key={subject} label="Subject"><b>Subject</b><br /> {subject}</Typography>
                        :<></>
                      }
                      {message !== ""?
                        <Box sx={{whiteSpace: "pre-wrap"}} key={message}><b>{channel === "WhatsApp/LinkedIn/Twitter DM etc."?"First Message":"Message"}</b><HighlightText text={message} /></Box>
                        :<></>
                      }
                      {script !== ""?
                        <Box sx={{whiteSpace: "pre-wrap"}} key={script}><b>Script</b><HighlightText text={script} /></Box>
                        :<></>
                      }
                    </Stack>

                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                      {channel === "Email/LinkedIn InMail"?
                      <>
                      <Button size="small" variant="outlined" startIcon={<ContentCopyIcon />} onClick={()=>{copyToClipboard(["subject", "message"])}}>Copy Subject & Message</Button>
                      </>
                      :channel === "WhatsApp/LinkedIn/Twitter DM etc."?
                      <>
                      <Button size="small" variant="outlined" startIcon={<ContentCopyIcon />} onClick={()=>{copyToClipboard(["message", "script"])}}>Copy First Message & Script</Button>
                      </>
                      :
                      <Button size="small" variant="outlined" startIcon={<ContentCopyIcon />} onClick={()=>{copyToClipboard("script")}}>Copy Script</Button>
                      }
                    </Stack>

                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" sx={{flexWrap: 'wrap'}}>
                      <Typography>🪄 Rewrite</Typography>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onRewrite("Make it formal")}>Formal</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onRewrite("Make it casual")}>Casual</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onRewrite("Make it humorous")}>Humorous</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onRewrite("Make it serious")}>Serious</Button>
                      <Stack direction="row" alignItems="center">
                        <TextField sx={{"& .MuiInputBase-input": {height: "30px", padding: "0 14px"}}} placeholder="Custom Instruction" value={instruction} onChange={e => setInstruction(e.target.value)} />
                        <Button size="small" variant="outlined" onClick={onRewrite}>Rewrite</Button>
                      </Stack>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" sx={{flexWrap: 'wrap'}}>
                      <Typography>Personalise using Buyer's</Typography>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onPersonalise(1)}>Social Media Post</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onPersonalise(2)}>Work & Expertise</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onPersonalise(3)}>Company/Industry News</Button>
                    </Stack>
                    {/*
                    [1,2,3].map((x, ind) =>
                      <Stack key={ind} direction="column" sx={{width: "100%"}} spacing={1}>
                        <Skeleton variant="text" width="100%" sx={{fontSize:"30"}}/>
                        <Skeleton variant="rectangular" width="100%" height="100"/>
                      </Stack>
                    )
                    */}
                  </Stack>
                  :<></>
                }
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
    </Dialog>
  )
}
