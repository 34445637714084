import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, ListSubheader, Typography, FormControl, FormHelperText, Select, MenuItem} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
import ToastComponent from "./../utils/ToastComponent";
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { PERSONAS, STAGE_MAPPING } from "./../utils/constants"
import {isValidUrl, isValidEmail} from "./../utils/helper"
import TipBox from "./../utils/TipBox"

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ModifySingleLead from "./ModifySingleLead"

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    sx: {textTransform: 'none'}
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index}
    id={`vertical-tabpanel-${index}`} {...other}>
      {value === index && (
        <Box sx={{ pt: 1.5, pb: 1.5 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const showColumn = (leadList) => {
  var showEmail = true
  var showLinkedIn = true
  var showTwitter = true
  var showPhone = true
  var condensed = ""

  if(!("Email" in leadList["data"])) showEmail = false
  else{
    condensed = leadList["data"]["Email"].reduce((total, x) => total + "x", "")
    if(condensed === "") showEmail = false
  }

  if(!("LinkedIn" in leadList["data"])) showLinkedIn = false
  else{
    condensed = leadList["data"]["LinkedIn"].reduce((total, x) => total + "x", "")
    if(condensed === "") showLinkedIn = false
  }

  if(!("Twitter" in leadList["data"])) showTwitter = false
  else{
    condensed = leadList["data"]["Twitter"].reduce((total, x) => total + "x", "")
    if(condensed === "") showTwitter = false
  }

  if(!("Phone" in leadList["data"])) showPhone = false
  else{
    condensed = leadList["data"]["Phone"].reduce((total, x) => total + "x", "")
    if(condensed === "") showPhone = false
  }

  return {
    email: showEmail,
    linkedin: showLinkedIn,
    twitter: showTwitter,
    phone: showPhone,
  }
}

const dbFields = ["cp", "persona", "industry", "vertical", "company_size", "team_size", "geography", "buying_signals_tech", "buying_signals_non_tech"]
const uiFields = ["Customer Profile", "Persona", "Industry", "Vertical", "Company Size", "Team Size", "Geography", "Technical Buying Signal", "Non-technical Buying Signal"]
var uiToDBFields = {}
for(var i=0;i<uiFields.length;i+=1){
  uiToDBFields[uiFields[i]] = dbFields[i]
}

const metadDataColumns = ["Customer Profile", "Persona", "Industry", "Vertical", "Geography", "Company Size", "Team Size", "Technical Buying Signal", "Non-technical Buying Signal"]
export default function Leads(props) {
  useEffect(() => {
    props.callback("heading", "Leads")
    props.callback("description", "List(s) of prospects that you are selling to")
    window.scrollTo(0,0) //scrolling to top of new screen
  }, [])

  const [tabValue, setTabValue] = useState(0);
  const [addNewList, setAddNewList] = useState(false)
  const [addNewLeads, setAddNewLeads] = useState(false)
  const [newListName, setNewListName] = useState("")
  const [newListNameError, setNewListNameError] = useState(false)
  const [leadLists, setLeadLists] = useState([])
  const [filteredFields, setFilteredFields] = useState([])
  const [leadFile, setLeadFile] = useState(null)
  const [leadFileData, setLeadFileData] = useState(null)
  const [errorMsgImportLeads, setErrorMsgImportLeads] = useState("")

  const [modifySingleLeadMode, setModifySingleLeadMode] = useState("")
  const [editLeadNo, setEditLeadNo] = useState(-1)

  const [addColumn, setAddColumn] = useState(false)
  const [addColumnName, setAddColumnName] = useState("")
  const [addColumnNameError, setAddColumnNameError] = useState(false)

  const [deleteColumn, setDeleteColumn] = useState(-1)
  const [deleteLeadList, setDeleteLeadList] = useState([-1, ""])
  const [columnMap, setColumnMap] = useState([])
  const [newColumnMap, setNewColumnMap] = useState([])
  const [activeFields, setActiveFields] = useState({})

  const [customerProfiles, setCustomerProfiles] = useState(null)

  const account_id = useSelector((state) => state.account.value);

  useEffect(() => {
    axios.get(BACKEND_ADDRESS + '/lead_list/all_lead_lists/', {params: {account_id: account_id}})
    .then((response) => {
      var leadListsValue = response.data["lead_lists"]

      setLeadLists(leadListsValue)
      updateFilteredFields(leadListsValue)
      if(leadListsValue.length > 0) setTabValue(1)
    })
    .catch((e) => ToastComponent("error", "Couldn't load existing Lead Lists"))
  }, [])

  useEffect(() => {
    axios.get(BACKEND_ADDRESS + '/cp/all_cps/', {params: {account_id: account_id}})
    .then((response) =>
      setCustomerProfiles(response.data["customer_profiles"])
    )
  }, [])

  const updateFilteredFields = (leadListsValue) => {
    var ff = []

    //show only contact columns that have non blank value
    for(var i=0;i<leadListsValue.length;i+=1){
      var filteredF = []

      if(leadListsValue[i]["data"]["Email"].length === 0) filteredF = leadListsValue[i]["fields"]
      else{
        var colData = showColumn(leadListsValue[i])

        for(var j=0;j<leadListsValue[i]["fields"].length;j+=1){
          if(leadListsValue[i]["fields"][j] === "Email" && !colData["email"]) continue
          if(leadListsValue[i]["fields"][j] === "LinkedIn" && !colData["linkedin"]) continue
          if(leadListsValue[i]["fields"][j] === "Twitter" && !colData["twitter"]) continue
          if(leadListsValue[i]["fields"][j] === "Phone" && !colData["phone"]) continue

          filteredF.push(leadListsValue[i]["fields"][j])
        }
      }

      ff.push(filteredF)
    }

    setFilteredFields(ff)
  }

  const onAddNewList = () => {
    if(newListName === ""){
      setNewListNameError(true)
      return
    }else{
      setNewListNameError(false)
    }

    setNewListName("")
    setAddNewList(false)

    //update data
    var newLeadLists = leadLists
    newLeadLists.push({
      account_id: account_id,
      name: newListName,
      fields: ["Email", "LinkedIn", "Twitter", "Phone", "Name"], //these are compulsory fields
      //filteredFields: ["Email"],
      data: {Email: [], LinkedIn: [], Twitter: [], Phone: [], Name: []},
      channel: [],
      metadata: {
        cp: [],
        persona: [],
        industry: [],
        vertical: [],
        company_size: [],
        team_size: [],
        geography: [],
        buying_signals_tech: [],
        buying_signals_non_tech: []
      },
      is_active: true
    })

    axios.post(BACKEND_ADDRESS + '/lead_list/', newLeadLists[newLeadLists.length - 1])
    .then(response => {
      newLeadLists[newLeadLists.length - 1]["id"] = response.data["id"] //update id created in backend
      setLeadLists(newLeadLists)
      setTabValue(newLeadLists.length)
      updateFilteredFields(newLeadLists)
    })
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onFileSelection = (event) => {
    setLeadFile(event.target.files[0])
  }

  const onFileSubmit = () => {
    if(leadFile === null){
      setErrorMsgImportLeads("Please upload a file in .csv format.")
      return
    }

    var formData = new FormData();
    formData.append('file', leadFile);
    formData.append('file_name', leadFile.name)
    formData.append('lead_list', JSON.stringify(leadLists[tabValue - 1]))
    const config = {headers: {'content-type': 'multipart/form-data'}}

    //sending to backend
    axios.post(BACKEND_ADDRESS + "/lead_list/read_from_file/", formData, config)
    .then((response) => {
      //evaluate backend response
      if(response.data["error"]){
        setErrorMsgImportLeads(response.data["error"])
      }else{
        //var updatedData = response.data["lead_list_new"]
        setLeadFileData(response.data["file_data"])
        setColumnMap(response.data["column_map"])
        setNewColumnMap(Array(response.data["column_map"].length).fill(""))
        setActiveFields(Object.keys(leadLists[tabValue-1]["data"]).concat(uiFields).reduce((acc, key) => ({...acc, [key]: true}), {}))
        setErrorMsgImportLeads("")
      }
    })
    .catch((error) => {
      setErrorMsgImportLeads(error.message)
    })
  }

  const updateColumnMap = (val, ind) => {
    var temp = JSON.parse(JSON.stringify(columnMap)) //deepcopy
    temp[ind][1] = val
    setColumnMap(temp)

    const mappedCols = temp.map(x=>x[1])
    var activeFieldsCopy = JSON.parse(JSON.stringify(activeFields))
    var keys = Object.keys(activeFieldsCopy)
    for(var i=0;i<keys.length;i+=1){
      activeFieldsCopy[keys[i]] = !(mappedCols.includes(keys[i])) //if includes then inactive
    }

    setActiveFields(activeFieldsCopy)
  }

  const editNewCol = (ind, val) => {
    var temp = JSON.parse(JSON.stringify(newColumnMap)) //deepcopy
    temp[ind] = val
    setNewColumnMap(temp)
  }

  const onColumnMappingSubmit = () => {
    const mappedCols = columnMap.map(x=>x[1])
    if(!(mappedCols.includes("Name") && ["Email", "LinkedIn", "Twitter", "Phone"].some(element => mappedCols.includes(element)))){
      setErrorMsgImportLeads("Mapped columns should have Name column and one of Email/LinkedIn/Twitter/Phone column.")
      return
    }

    var updatedData = JSON.parse(JSON.stringify(leadLists[tabValue - 1])) //deepcopy
    const originalLeadCount = updatedData["data"]["Email"].length

    var cp_list = []
    var has_persona = []

    for(var i=0;i<columnMap.length;i+=1){
      const isNewCol = (newColumnMap[i] !== "")
      const fileColumn = columnMap[i][0]
      const dataColumn = isNewCol?newColumnMap[i]:columnMap[i][1]

      if(uiFields.includes(dataColumn)){
        // metadata fields

        if(dataColumn === "Customer Profile"){
          for(var j=0;j<leadFileData[fileColumn].length;j+=1){
            var gotit = false
            for(var k=0;k<customerProfiles.length;k+=1){
              if(customerProfiles[k]["name"] === leadFileData[fileColumn][j]){
                cp_list.push(customerProfiles[k])
                gotit = true
                break
              }
            }
            if(!gotit) cp_list.push("")
          }
          updatedData["metadata"]["cp"] = updatedData["metadata"]["cp"].concat(cp_list)
        }else if(dataColumn === "Persona"){
          has_persona = leadFileData[fileColumn]
        }else{
          updatedData["metadata"][uiToDBFields[dataColumn]] = updatedData["metadata"][uiToDBFields[dataColumn]].concat(leadFileData[fileColumn])
        }
      }else if(Object.keys(updatedData["data"]).includes(dataColumn)){
        // data fields
        updatedData["data"][dataColumn] = updatedData["data"][dataColumn].concat(leadFileData[fileColumn])
      }else {
        // new field in data
        updatedData["data"][dataColumn] = Array(originalLeadCount).fill("").concat(leadFileData[fileColumn])
        updatedData["fields"].push(dataColumn)
      }
    }

    //fill persona field
    if(has_persona.length > 0 && cp_list.length > 0){
      var persona_list = []
      for(var j=0;j<has_persona.length;j+=1){
        var gotit = false
        if(cp_list[j] !== ""){
          for(var k=0;k<cp_list[j]["personas"].length;k+=1){
            var persona = cp_list[j]["personas"][k]
            if(has_persona[j] === persona["designation"] + " at " + persona["vertical"]){
              persona_list.push(persona)
              gotit = true
              break
            }
          }
        }
        if(!gotit) persona_list.push("")
      }

      updatedData["metadata"]["persona"] = updatedData["metadata"]["persona"].concat(persona_list)
    }

    //fill all unfilled fields with blank values
    var dataFields = Object.keys(updatedData["data"])
    var maxLength = updatedData["data"]["Name"].length
    for(var i=0;i<dataFields.length;i+=1){
      const length = updatedData["data"][dataFields[i]].length
      if(length < maxLength){
        updatedData["data"][dataFields[i]] = updatedData["data"][dataFields[i]].concat(Array(maxLength-length).fill(""))
      }
    }
    dataFields = Object.keys(updatedData["metadata"])
    for(var i=0;i<dataFields.length;i+=1){
      const length = updatedData["metadata"][dataFields[i]].length
      if(length < maxLength){
        updatedData["metadata"][dataFields[i]] = updatedData["metadata"][dataFields[i]].concat(Array(maxLength-length).fill(""))
      }
    }

    var updatedLeadLists = leadLists
    updatedLeadLists[tabValue - 1] = updatedData
    setLeadLists(updatedLeadLists)
    axios.put(BACKEND_ADDRESS + '/lead_list/', updatedLeadLists[tabValue - 1])
    updateFilteredFields(updatedLeadLists)

    //deactivate popup
    setAddNewLeads(false)
    setColumnMap([])
    ToastComponent("info", "Leads imported successfully")
  }

  const updateLeads = (leadData) => {
    if(Object.keys(leadData).length === 0){
      //cancel was clicked
      //reset
      setEditLeadNo(-1)
      setModifySingleLeadMode("")
      return
    }

    var keys = Object.keys(leadLists[tabValue-1]["data"])
    const updatedLeadLists = leadLists

    for(var i=0;i<keys.length;i+=1){
      if(modifySingleLeadMode === "edit") updatedLeadLists[tabValue-1]["data"][keys[i]][editLeadNo] = leadData[keys[i]]
      else if(modifySingleLeadMode === "add")  updatedLeadLists[tabValue-1]["data"][keys[i]].push(leadData[keys[i]])
    }

    keys = Object.keys(leadLists[tabValue-1]["metadata"])
    for(i=0;i<keys.length;i+=1){
      if(modifySingleLeadMode === "edit") updatedLeadLists[tabValue-1]["metadata"][keys[i]][editLeadNo] = leadData["metadata"][keys[i]]
      else if(modifySingleLeadMode === "add") updatedLeadLists[tabValue-1]["metadata"][keys[i]].push(leadData["metadata"][keys[i]])
    }

    setLeadLists(updatedLeadLists)
    axios.put(BACKEND_ADDRESS + '/lead_list/', updatedLeadLists[tabValue - 1])
    updateFilteredFields(updatedLeadLists)

    //reset
    setEditLeadNo(-1)
    setModifySingleLeadMode("")
  }

  const deleteLead = (rowIndex) => {
    const keys = Object.keys(leadLists[tabValue-1]["data"])
    const updatedLeadLists = JSON.parse(JSON.stringify(leadLists)) //deepcopy as deletion is involved

    for(var i=0;i<keys.length;i+=1){
      updatedLeadLists[tabValue-1]["data"][keys[i]].splice(rowIndex, 1) //delete rowIndex'th element
    }

    setLeadLists(updatedLeadLists)
    axios.put(BACKEND_ADDRESS + '/lead_list/', updatedLeadLists[tabValue - 1])
    updateFilteredFields(updatedLeadLists)
  }

  const onAddColumn = () => {
    if(addColumnName === ""){
      setAddColumnNameError(true)
      return
    }else{
      setAddColumnNameError(false)
    }

    var updatedLeadLists = JSON.parse(JSON.stringify(leadLists))
    updatedLeadLists[tabValue - 1]["fields"].push(addColumnName)
    //updatedLeadLists[tabValue - 1]["filteredFields"].push(addColumnName)
    updatedLeadLists[tabValue - 1]["data"][addColumnName] = Array.from(updatedLeadLists[tabValue - 1]["data"]["Email"], x => "")
    //update
    setLeadLists(updatedLeadLists)
    axios.put(BACKEND_ADDRESS + '/lead_list/', updatedLeadLists[tabValue - 1])
    updateFilteredFields(updatedLeadLists)

    //reset
    setAddColumn(false)
    setAddColumnName("")
  }

  const onDeleteColumn = () => {
    var updatedLeadLists = JSON.parse(JSON.stringify(leadLists)) //deepcopy as deletion is involved
    const column_name = updatedLeadLists[tabValue - 1]["fields"][deleteColumn]
    updatedLeadLists[tabValue - 1]["fields"].splice(deleteColumn, 1)
    delete updatedLeadLists[tabValue - 1]["data"][column_name]

    //update
    setLeadLists(updatedLeadLists)
    axios.put(BACKEND_ADDRESS + '/lead_list/', updatedLeadLists[tabValue - 1])
    updateFilteredFields(updatedLeadLists)

    //reset
    setDeleteColumn(-1)
  }

  const onDeleteLeadList = () => {
    var updatedLeadLists = JSON.parse(JSON.stringify(leadLists)) //deepcopy as deletion is involved
    const id = ("_id" in leadLists[deleteLeadList[0]])?leadLists[deleteLeadList[0]]["_id"]["$oid"]:leadLists[deleteLeadList[0]]["id"] //both possibilities can be there.
    updatedLeadLists.splice(deleteLeadList[0], 1)

    //update
    setLeadLists(updatedLeadLists)
    axios.delete(BACKEND_ADDRESS + '/lead_list/', {params: {id: id}})

    //reset
    setDeleteLeadList([-1, ""])
    setTabValue(deleteLeadList[0]) //set to last lead list
  }

  return (
    <>
    <Stack direction="column" spacing={1}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable" value={tabValue}
        onChange={handleTabChange} sx={{backgroundColor:"#ebebeb", borderRight: 1, borderColor: 'divider', width: "100%"}}>
          <Tab autoFocus style={{backgroundColor: "#395af6", color:"white", borderRadius: "8px"}} label={<Stack direction="row" alignItems="center" spacing={1}><AddIcon size="small"/>New List</Stack>}
           onClick={() => setAddNewList(true)} sx={{border: "1px solid #000000"}}{...a11yProps(-1)}/>
          {leadLists.map((x, ind) => {
            return (
              <Tab key={ind} label={x["name"]} {...a11yProps(ind)} />
            )
          })}
        </Tabs>
      </Box>
      {leadLists.length === 0?
      <><br /><Typography><i>You haven't created any Lead Lists yet.</i></Typography></>
      :
      leadLists.map((leadList, ind) =>
        <TabPanel key={ind} value={tabValue-1} index={ind} style={{marginTop:0, width: "100%", overflowX: "hidden", padding: ""}}>
          <Stack direction="row" spacing={1}>
            <Typography variant="h6" sx={{flexGrow: 1}}>
              {leadList["name"]}
              <IconButton size="small" onClick={() => setDeleteLeadList([ind, leadList["name"]])}><DeleteIcon /></IconButton>
            </Typography>
            <Button startIcon={<AddIcon />} onClick={() => setModifySingleLeadMode("add")}>New Lead</Button>
            <Button variant="outlined" startIcon={<AddIcon />} onClick={() => setAddNewLeads(true)}>Import Leads</Button>
          </Stack>
          <Divider sx={{margin: "12 0"}}/>
          {leadList["fields"].length > 0?
            <TableContainer component={Paper} sx={{ maxHeight: "96vh"}}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell rowSpan={2} style={{backgroundColor: "#f5f5f5"}} />
                    {filteredFields.length === leadLists.length && filteredFields[ind].map((x, ind)=>
                      <TableCell style={{backgroundColor: "#f5f5f5"}} rowSpan={2} key={ind} >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle2" >{x}</Typography>
                          {x !== "Email" && x !== "LinkedIn" && x !== "Twitter" && x !== "Phone" &&  x !== "Name"?
                          <IconButton size="small" onClick={() => setDeleteColumn(ind)}><DeleteIcon /></IconButton>
                          :<></>}
                        </Stack>
                      </TableCell>
                    )}
                    <TableCell style={{backgroundColor: "#f5f5f5"}} colSpan={metadDataColumns.length}><center><Typography variant="subtitle2" >Customer Profile Tags</Typography></center></TableCell>
                    <TableCell sx={{p:1, backgroundColor: "#ebebeb", minWidth: 130, position: 'sticky', right: 0, zIndex: 3}} rowSpan={2}>
                      <Button variant="outlined" sx={{backgroundColor: "white", "&:hover": {backgroundColor: "white"}}} onClick={() => setAddColumn(true)}>Add Column</Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {metadDataColumns.map((x, id) =>
                      <TableCell key={id} sx={{minWidth:150, backgroundColor: "#f5f5f5"}}><Typography variant="subtitle2">{x}</Typography></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from({length: leadList["data"]["Email"].length }).map((_, rowIndex) =>{
                    return (
                      <TableRow key={rowIndex} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell>{rowIndex+1}</TableCell>
                        {filteredFields.length === leadLists.length && filteredFields[ind].map((field, colIndex) =>
                          <TableCell key={field} component="th" scope="row">
                            {isValidUrl(leadList["data"][field][rowIndex])?
                              <a target="_blank" href={leadList["data"][field][rowIndex]}>
                                {leadList["data"][field][rowIndex]}
                              </a>
                            :isValidEmail(leadList["data"][field][rowIndex])?
                              <a href={"mailto:"+leadList["data"][field][rowIndex]}>
                                <Chip avatar={<Avatar>{leadList["data"][field][rowIndex][0]}</Avatar>}
                                  variant="outlined" label={leadList["data"][field][rowIndex]} />
                              </a>
                            :leadList["data"][field][rowIndex]
                            }
                          </TableCell>
                        )}
                        <TableCell>
                          <Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["cp"][rowIndex]?leadList["metadata"]["cp"][rowIndex]["name"]:""} sx={{color: "primary.main"}}/>&nbsp;
                        </TableCell>
                        <TableCell>
                          <Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["persona"][rowIndex]?leadList["metadata"]["persona"][rowIndex]["designation"] + " at " + leadList["metadata"]["persona"][rowIndex]["vertical"]:""} sx={{color: "primary.main"}}/><br />
                        </TableCell>
                        <TableCell>
                          <Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["industry"][rowIndex]} sx={{color: "primary.main"}}/>&nbsp;
                        </TableCell>
                        <TableCell>
                          <Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["vertical"][rowIndex]} sx={{color: "primary.main"}}/>&nbsp;
                        </TableCell>
                        <TableCell>
                          <Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["geography"][rowIndex]} sx={{color: "primary.main"}}/><br />
                        </TableCell>
                        <TableCell>
                          <Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["company_size"][rowIndex]} sx={{color: "primary.main"}}/>&nbsp;
                        </TableCell>
                        <TableCell>
                          <Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["team_size"][rowIndex]} sx={{color: "primary.main"}}/><br />
                        </TableCell>
                        <TableCell>
                          <Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["buying_signals_tech"][rowIndex]} sx={{color: "primary.main"}}/>&nbsp;
                        </TableCell>
                        <TableCell>
                          <Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["buying_signals_non_tech"][rowIndex]} sx={{color: "primary.main"}}/><br />
                        </TableCell>
                        <TableCell sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'sticky', right: 0, backgroundColor: "#ebebeb"}}>
                          <IconButton size="small" onClick={() => {setModifySingleLeadMode("edit"); setEditLeadNo(rowIndex)}}><EditIcon /></IconButton>
                          <IconButton size="small" onClick={() => deleteLead(rowIndex)}><DeleteIcon /></IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          :<center><Typography><br /><br/><i>Lead List is Empty</i></Typography></center>
          }
        </TabPanel>
      )}
    </Stack>

    <Dialog open={addNewList}>
      <DialogTitle >
        Create a New List
      </DialogTitle>
      <DialogContent>
        <TextField placeholder="Product Leaders List" label="Lead List Name" onChange={(e) => setNewListName(e.target.value)}
        error={newListNameError} helperText={newListNameError?"List Name can't be blank":""} defaultValue={newListName}
        variant="standard" required sx={{width: "400px"}} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => {setAddNewList(false);setTabValue(1)}}>Cancel</Button>
        <Button variant="contained" onClick={onAddNewList} autoFocus>Create List</Button>
      </DialogActions>
    </Dialog>

    <Dialog open={addNewLeads} maxWidth="md" fullWidth>
      <DialogTitle >
        Import Leads
      </DialogTitle>
      <DialogContent>
        {columnMap.length === 0?
          <Stack direction="column" spacing={1} alignItems="center">
            <Typography variant="h6">Upload a file (CSV)</Typography>
            <TipBox tip={<Typography>
            Your file must use commas or semicolons as column delimiters.
            To be valid, your file must contain at least one of <i>Email/LinkedIn/Twitter/Phone</i> column. And it should contain <i>Name</i> column.
            </Typography>} />
            <input type="file" onChange={onFileSelection}/>
            <Typography color="red" variant="subtitle2">{errorMsgImportLeads}</Typography>
          </Stack>
          :
          <Stack direction="column" spacing={1} alignItems="center">
            <Typography variant="h6">Map Columns</Typography>
            <Typography>Map columns from imported file to column in existing lead list.</Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead style={{backgroundColor: "#f5f5f5"}}>
                  <TableRow>
                    <TableCell>File Column</TableCell>
                    <TableCell>Mapped Column</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {columnMap.map((x, ind) =>
                    <TableRow key={ind}>
                      <TableCell>{x[0]}</TableCell>
                      <TableCell>
                        {newColumnMap[ind] === ""?
                          <Stack direction="row" spacing={2}>
                          <FormControl sx={{width: "50%"}} required error={x[1]===""}>
                          <Select size="small" required value={x[1]}
                          variant="standard" onChange={e => updateColumnMap(e.target.value, ind)}>
                            {Object.keys(leadLists[tabValue-1]["data"]).map((x, id) =>
                              <MenuItem key={id} value={x} disabled={!activeFields[x]}>{x}</MenuItem>
                            )}
                            <ListSubheader>CP Fields</ListSubheader>
                            {uiFields.map((x, id) =>
                              <MenuItem key={id} value={x} disabled={!activeFields[x]}>{x}</MenuItem>
                            )}
                          </Select>
                          {x[1]===""?<FormHelperText sx={{ml:0}}>Select a Column</FormHelperText>:<></>}
                          </FormControl>
                          <Button onClick={()=>editNewCol(ind, x[0])} disabled={Object.keys(leadLists[tabValue-1]["data"]).includes(x[0]) || Object.keys(leadLists[tabValue-1]["metadata"]).includes(uiToDBFields[x[0]])}>
                            Create New Column Instead
                          </Button>
                          </Stack>
                          :
                          <Typography>
                          {/*<FormControl sx={{width: "95%"}} required error={newColumnMap[ind] === ""}>
                            <TextField size="small" variant="standard" value={newColumnMap[ind]} onChange={e => editNewCol(ind, e.target.value)} />
                            {newColumnMap[ind] === ""?<FormHelperText sx={{ml:0}}>Column Name can't be blank</FormHelperText>:<></>}
                          </FormControl>
                          */}
                          {newColumnMap[ind]}
                          </Typography>
                        }
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography color="red" variant="subtitle2">{errorMsgImportLeads}</Typography>
          </Stack>
        }
      </DialogContent>
      <DialogActions>
        {columnMap.length === 0?
        <>
        <Button variant="outlined" onClick={() => {setAddNewLeads(false); setLeadFile(null); setErrorMsgImportLeads("")}}>Cancel</Button>
        <Button variant="contained" autoFocus startIcon={<CloudUploadIcon />} onClick={onFileSubmit}>Upload</Button>
        </>
        :
        <>
        <Button variant="outlined" onClick={() => {setAddNewLeads(false); setLeadFile(null); setColumnMap([]); setErrorMsgImportLeads("")}}>Cancel</Button>
        <Button variant="contained" autoFocus onClick={onColumnMappingSubmit}>Submit</Button>
        </>
        }
      </DialogActions>
    </Dialog>

    <Dialog open={addColumn}>
      <DialogTitle >
        Add Column
      </DialogTitle>
      <DialogContent>
        <TextField label="Column Name" onChange={(e) => setAddColumnName(e.target.value)}
        error={addColumnNameError} helperText={addColumnNameError?"Column Name can't be blank":""} defaultValue={addColumnName}
        variant="standard" required sx={{width: "400px"}} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => {setAddColumn(false);setAddColumnName("");setAddColumnNameError(false)}}>Cancel</Button>
        <Button variant="contained" autoFocus onClick={onAddColumn}>Add Column</Button>
      </DialogActions>
    </Dialog>

    <Dialog open={deleteColumn>=0}>
      <DialogTitle >
        Delete Column
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this column?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => {setDeleteColumn(false)}}>Cancel</Button>
        <Button variant="contained" autoFocus onClick={onDeleteColumn}>Delete Column</Button>
      </DialogActions>
    </Dialog>

    <Dialog open={deleteLeadList[0] > -1}>
      <DialogTitle >
        Delete Lead List?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Leads once lost, can't be retrieved. Are you sure you want to delete <b><i>{deleteLeadList[1]}</i></b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => {setDeleteLeadList([-1, ""])}}>Cancel</Button>
        <Button variant="contained" onClick={onDeleteLeadList} sx={{backgroundColor: "red"}}>I am sure. Delete {deleteLeadList[1]}</Button>
      </DialogActions>
    </Dialog>

    {modifySingleLeadMode === "edit" && editLeadNo !== -1?
      <ModifySingleLead key={editLeadNo} mode="edit" data={leadLists} leadListNo={tabValue-1} leadNo={editLeadNo} callback={updateLeads} />
      :<></>
    }

    {modifySingleLeadMode === "add"?
      <ModifySingleLead key={-1} mode="add" data={leadLists} leadListNo={tabValue-1} callback={updateLeads} />
      :<></>
    }
    </>
  )
}