import React, {useState,useEffect} from "react";
import fire from "./base.js"

export const AuthContext = React.createContext();

export const AuthProvider=({children})=>{
    const[currentUser,setCurrentuser]=useState(null);

    useEffect(()=>{
        fire.auth().onAuthStateChanged((user)=>{
            if(user!==null){
                setCurrentuser(user);
                
            }
        }
        );
    },[]);

    return(
        <AuthContext.Provider
        value={{
            currentUser
        }}
        >
           {children}
        </AuthContext.Provider>
    );

};