import {Grid, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';

export default function TipBox(props){
  return (
    <Paper style={{borderRadius: "8px", padding: "24px", backgroundColor: "#f1f1ef"}} elevation={0}>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={1}><Typography variant="h5">💡</Typography></Grid>
        <Grid item xs={11}>
          {props.tip}
        </Grid>
      </Grid>
    </Paper>
  )
}
