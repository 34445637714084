import React, { useState, useEffect, useRef } from 'react';
import {Skeleton, ToggleButton, ToggleButtonGroup, FormHelperText, Accordion, AccordionSummary, AccordionDetails, Typography, Box, IconButton, Button, Stack, Grid, Chip, Paper, TextField, Toolbar, Radio, Checkbox, RadioGroup} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider'

import TipBox from "./../utils/TipBox"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { useSelector } from 'react-redux'
import ToastComponent from '../utils/ToastComponent';

const allChannels = ["Email/LinkedIn InMail", "WhatsApp/LinkedIn/Twitter DM etc.", "Cold Call", "Face-to-face/At Events"]
//only first two channels allowed in followup
const allFollowUpChannels = ["Email/LinkedIn InMail", "WhatsApp/LinkedIn/Twitter DM etc."]

// const allCTAs = ["Video Call", "In Person"]

// function replaceConditionalValues(str, key, x) {
//   const regex = new RegExp(`\\[${key}\\?([^:]+):([^\\]]+)\\]`, 'g');
  
//   return str.replace(regex, (_, val1, val2) => {
//     return x ? val1 : val2;
//   });
// }

const HighlightText = ({ text }) => {
  // Regex to find all curly braces content
  const regex = /{([^}]+)}/g;
  const parts = text.split(regex);

  return (
    <Box>
      {parts.map((part, index) =>
        index % 2 === 1 ? (
          <Box component="span" key={index} sx={{ color: 'red' }}>
            {"{" + part + "}"}
          </Box>
        ) : (
          <Box component="span" key={index}>
            {part}
          </Box>
        )
      )}
    </Box>
  );
};

export default function FollowUp(props){
  var listNo = props.listNo
  var leadNo = props.leadNo
  var campaign = props.campaign
  var leadList = campaign["leads"][listNo]
  var channel = campaign["lead_status"][listNo][leadNo][campaign["lead_status"][listNo][leadNo].length - 2]["channel"] //-2 so that you get channel from the one before this sequence
  var oldMessage = campaign["lead_status"][listNo][leadNo][campaign["lead_status"][listNo][leadNo].length - 2]["message"]
  var oldScript = campaign["lead_status"][listNo][leadNo][campaign["lead_status"][listNo][leadNo].length - 2]["script"]

//   var allProblems = props.cp["problems"]
//   var allIndustries = props.cp["industries"]
//   var allVerticals = props.cp["verticals"]

//   const sellerName = useSelector((state) => state.name.value);
//   const hasProduct = campaign["hasProduct"]
  
  const [followUpChannel, setFollowUpChannel] = useState("")
//   const [name, setName] = useState(leadList["data"]["Name"][leadNo])
//   const [problem, setProblem] = useState(allProblems[0])
//   const [industry, setIndustry] = useState(leadList["metadata"]["industry"][leadNo])
//   const [vertical, setVertical] = useState(leadList["metadata"]["vertical"][leadNo])
//   const [cta, setCta] = useState("Video Call")
//   const [meetingLink, setMeetingLink] = useState("")
//   const [areas, setAreas] = useState("")
//   const [referenceName, setReferenceName] = useState("")

  const [followUpChannelError, setFollowUpChannelError] = useState(false)
//   const [nameError, setNameError] = useState(false)
//   const [problemError, setProblemError] = useState(false)
//   const [industryError, setIndustryError] = useState(false)
//   const [verticalError, setVerticalError] = useState(false)
//   const [ctaError, setCtaError] = useState(false)
//   const [meetingLinkError, setMeetingLinkError] = useState(false)
//   const [areasError, setAreasError] = useState(false)
//   const [referenceNameError, setReferenceNameError] = useState(false)

  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [script, setScript] = useState("")
  const [instruction, setInstruction] = useState("")

  const copyToClipboard = (section) => {
    var flag = true

    if(section === "message" || section === "script"){
      if(followUpChannel === ""){
        setFollowUpChannelError(true)
        flag = false
      }else setFollowUpChannelError(false)
  
    //   if(name === ""){
    //     setNameError(true)
    //     flag = false
    //   }else setNameError(false)
  
    //   if(problem === ""){
    //     setProblemError(true)
    //     flag = false
    //   }else setProblemError(false)
  
    //   if(industry === ""){
    //     setIndustryError(true)
    //     flag = false
    //   }else setIndustryError(false)
  
    //   if(vertical === ""){
    //     setVerticalError(true)
    //     flag = false
    //   }else setVerticalError(false)
  
    //   if(cta === ""){
    //     setCtaError(true)
    //     flag = false
    //   }else setCtaError(false)

    //   if(meetingLink === ""){
    //     setMeetingLinkError(true)
    //     flag = false
    //   }else setMeetingLinkError(false)
  
    //   if(areas === ""){
    //     setAreasError(true)
    //     flag = false
    //   }else setAreasError(false)
      
    //   if(channel === "WhatsApp/LinkedIn/Twitter DM etc."){
    //     if(referenceName === ""){
    //       setReferenceNameError(true)
    //       flag = false
    //     }else setReferenceNameError(false)
    //   }
    }

    if(flag){
      if(section === "subject") navigator.clipboard.writeText(subject)
      else if(section === "message") navigator.clipboard.writeText(message)
      else if(section === "script") navigator.clipboard.writeText(script)

      ToastComponent("info", "Copied Successfully!")
      
      var obj = {
        type: "Follow Up", 
        status: "completed",
        subject: subject, 
        message: message,
        script: script,
        channel: channel,
        followUpChannel: followUpChannel
      }
  
      var newActiveCampaign = JSON.parse(JSON.stringify(campaign))
      const statusLength = newActiveCampaign["lead_status"][listNo][leadNo].length
      newActiveCampaign["lead_status"][listNo][leadNo][statusLength - 1] = obj
  
      // update everywhere 
      axios.put(BACKEND_ADDRESS + '/campaign/', newActiveCampaign)
      props.callback(newActiveCampaign) // in the very end
    } 
    else ToastComponent("error", "Failed to copy!")
  }

//   const updateContent = (field, copies) => {
//     const sections = ["subject", "message", "script"]
//     var newSectionValues 
    
//     if (copies === null) newSectionValues = [subject, message, script]
//     else newSectionValues = copies

//     for(var i =0;i<sections.length;i+=1){
//       if(newSectionValues[i] !== ""){
//         if(field === "name" || field === "all" && name!== "")newSectionValues[i] = newSectionValues[i].replaceAll("{name}", name)
//         if(field === "problem" || field === "all" && problem !== "")newSectionValues[i] = newSectionValues[i].replaceAll("{problem}", problem)
//         if(field === "industry" || field === "all" && industry !== "")newSectionValues[i] = newSectionValues[i].replaceAll("{industry}", industry)
//         if(field === "vertical" || field === "all" && vertical !== "")newSectionValues[i] = newSectionValues[i].replaceAll("{vertical}", vertical)
//         if(field === "cta" || field === "all" && cta !== "")newSectionValues[i] = replaceConditionalValues(newSectionValues[i], "meet_in_person", cta === "In Person")
//         if(field === "meetingLink" || field === "all" && meetingLink !== "")newSectionValues[i] = newSectionValues[i].replaceAll("{meeting_link}", meetingLink)
//         if(field === "areas" || field === "all" && areas !== "")newSectionValues[i] = newSectionValues[i].replaceAll("{add_areas}", areas)
//         if(field === "referenceName" || field === "all" && referenceName !== "")newSectionValues[i] = newSectionValues[i].replaceAll("{friend_name}", referenceName)
//       }

//       if(field === "all"){
//         newSectionValues[i] = newSectionValues[i].replaceAll("{seller_name}", sellerName)
//         newSectionValues[i] = replaceConditionalValues(newSectionValues[i], "has_product", hasProduct)
//       }
//     }

//     setSubject(newSectionValues[0])
//     setMessage(newSectionValues[1])
//     setScript(newSectionValues[2])
//   }

  useEffect(() => {
    var ind = 0
    for(var i =0;i<allFollowUpChannels.length;i+=1){
      if(allFollowUpChannels[i] === followUpChannel){
        ind = i
        break
      }
    }
    var obj = props.campaign["copy_data"][ind]["copy"][1] //1 index for followup
    var newSubject, newMessage, newScript;

    var data = {
        message: oldMessage,
        script: oldScript,
        channel: channel,
        followUpChannel: followUpChannel
    }

    axios.post(BACKEND_ADDRESS + '/campaign/generate_follow_up/', data)
    .then(response => {
        var result = response.data
        setMessage(result["message"])
        setScript(result["script"])
        setSubject("")
    })

    //updateContent("all", [newSubject, newMessage, newScript])
  }, [followUpChannel])

  // useEffect(() => updateContent("name", null), [name])
  // useEffect(() => updateContent("problem", null), [problem])
  // useEffect(() => updateContent("industry", null), [industry])
  // useEffect(() => updateContent("vertical", null), [vertical])
  // useEffect(() => updateContent("cta", null), [cta])
  // useEffect(() => updateContent("meetingLink", null), [meetingLink])
  // useEffect(() => updateContent("areas", null), [areas])
  // useEffect(() => updateContent("referenceName", null), [referenceName])

  const onPersonalise = (method) => {
    axios.post(BACKEND_ADDRESS + '/campaign/personalise_message/', {type: method, message: message, script: script})
    .then(response => {
      setMessage(response.data["message"])
      setScript(response.data["script"])
    }).catch(e => ToastComponent("error", "Can't personalise message/script"))
  }

  const onRewrite = () => {
    axios.post(BACKEND_ADDRESS + '/campaign/rewrite_message/', {instruction: instruction, message: message, script: script})
    .then(response => {
      setMessage(response.data["message"])
      setScript(response.data["script"])
    }).catch(e => ToastComponent("error", "Can't rewrite message/script"))
  }

  // const handleCopyDataChange = (a, b, c) => {
  // }

  return (
    <Dialog open={true} fullWidth maxWidth="lg" onClose={()=>props.callback(campaign)}>
      <DialogTitle >
        Follow Up
      </DialogTitle>
      <DialogContent>
        <Paper elevation={0} sx={{width: "100%", overflowY: "auto", border: "1px solid rgba(0,0,0,0.12)", borderRadius: 2}}>
          <Grid container direction="row" spacing={2} >
            <Grid item xs={12} md={4}>
              <Paper square sx={{padding: "24 16", height: "100%", minHeight: "500px", borderRight:"1px solid rgba(0, 0, 0, 0.12)", borderRadiusTopLeft: 2, borderRadiusBottomLeft: 2, backgroundColor: "#f5f5f5"}} elevation={0}>
                <Stack direction="column" spacing={1} sx={{mb:4}}>
                  <FormLabel sx={{color: "#395af6"}}>Copy/Script Settings</FormLabel>
                  <div style={{marginTop: "12"}}></div>
                  <FormControl sx={{width: "95%"}} required>
                    <InputLabel sx={{ml: -2}}>First Contact Platform/Channel</InputLabel>
                    <Select size="small" required value={channel}
                    variant="standard">
                      <MenuItem value={channel}>{channel}</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{width: "95%"}} required error={followUpChannelError}>
                    <InputLabel sx={{ml: -2}}>Follow Up Platform/Channel</InputLabel>
                    <Select size="small" autoFocus required value={followUpChannel}
                    variant="standard" onChange={(e) => {setFollowUpChannel(e.target.value)}}>
                      {allFollowUpChannels.map((x, ind) => 
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {followUpChannelError?<FormHelperText sx={{ml:0}}>Select a Platform/Channel</FormHelperText>:<></>}
                  </FormControl>
                
                  {/*channel === "WhatsApp/LinkedIn/Twitter DM etc."?
                  <FormControl sx={{width: "95%"}} error={referenceNameError}>
                    <TextField placeholder="Sam Altman" required size="small" label="Lead's Contact Shared by" value={referenceName}
                    variant="standard" onChange={(e) => {setReferenceName(e.target.value)}} />
                    {referenceNameError?<FormHelperText sx={{ml:0}}>Please fill a valid Name</FormHelperText>:<></>}
                  </FormControl>
                  :<></>}

                  <FormControl sx={{width: "95%"}} error={areasError}>
                    <TextField placeholder="" required size="small" label="Topic/area for which guidance is needed from lead" value={areas}
                    variant="standard" onChange={(e) => {setAreas(e.target.value)}}>
                      {areas}
                    </TextField>
                    {areasError?<FormHelperText sx={{ml:0}}>Please add a topic/area</FormHelperText>:<></>}
                  </FormControl>
                  
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography>Meeting CTA</Typography>
                    <ToggleButtonGroup size="small" exclusive color="primary" value={cta} onChange={(e, v) => {setCta(e.target.textContent)}}>
                      {allCTAs.map((x, ind) => 
                        <ToggleButton key={ind} aria-label={x} value={x} sx={{textTransform: 'none'}}>
                          <Typography>{x}</Typography>
                        </ToggleButton>
                      )}
                    </ToggleButtonGroup>
                  </Stack>

                  <FormControl sx={{width: "95%"}} error={meetingLinkError}>
                    <TextField required size="small" label="Meeting Link" value={meetingLink}
                    variant="standard" onChange={e => setMeetingLink(e.target.value)}>
                      {meetingLink}
                    </TextField>
                    {meetingLinkError?<FormHelperText sx={{ml:0}}>Please add a meeting link</FormHelperText>:<></>}
                  </FormControl>
                </Stack>

                <Stack direction="column" spacing={1}>
                  <FormLabel sx={{color: "#395af6"}}>Lead Details</FormLabel>
                  
                  <FormControl sx={{width: "95%"}} error={nameError}>
                    <TextField placeholder="Satya Nadella" required size="small" label="Name" value={name}
                    variant="standard" onChange={e => setName(e.target.value)}>
                      {name}
                    </TextField>
                    {nameError?<FormHelperText sx={{ml:0}}>Please add lead name</FormHelperText>:<></>}
                  </FormControl>

                  <FormControl sx={{width: "95%"}} required error={problemError}>
                    <InputLabel sx={{ml: -2}}>Buyer Problem</InputLabel>
                    <Select size="small" value={problem}
                    variant="standard" onChange={(e) => setProblem(e.target.value)}>
                      {allProblems.map((x, ind) => 
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {problemError?<FormHelperText sx={{ml:0}}>Please select a buyer problem for this lead</FormHelperText>:<></>}
                  </FormControl>

                  <FormControl sx={{width: "95%"}} required error={industryError}>
                    <InputLabel sx={{ml: -2}}>Industry</InputLabel>
                    <Select size="small" value={industry}
                    variant="standard" onChange={(e) => setIndustry(e.target.value)}>
                      {allIndustries.map((x, ind) => 
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {industryError?<FormHelperText sx={{ml:0}}>Please select an industry</FormHelperText>:<></>}
                  </FormControl>

                  <FormControl sx={{width: "95%"}} required error={verticalError}>
                    <InputLabel sx={{ml: -2}}>Vertical</InputLabel>
                    <Select size="small" value={vertical}
                    variant="standard" onChange={(e) => setVertical(e.target.value)}>
                      {allVerticals.map((x, ind) => 
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {verticalError?<FormHelperText sx={{ml:0}}>Please select a vertical</FormHelperText>:<></>}
                  </FormControl>
                  */}
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper sx={{padding: "24 16", height: "100%"}} elevation={0}>
                {followUpChannel !== ""?
                  <Stack direction="column" sx={{width: "100%", flexWrap: 'wrap'}} spacing={1} >
                    <Stack direction="column" spacing={4} sx={{mt:0}}>
                      {subject !== ""?
                        <Typography key={subject} label="Subject"><b>Subject</b><br /> {subject}</Typography>
                        :<></>
                      }
                      {message !== ""?
                        <Box sx={{whiteSpace: "pre-wrap"}} key={message}><b>Message</b><HighlightText text={message} /></Box>
                        :<></>
                      }
                      {script !== ""?
                        <Box sx={{whiteSpace: "pre-wrap"}} key={script}><b>Script</b><HighlightText text={script} /></Box>
                        :<></>
                      }
                    </Stack>
                    
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                      {followUpChannel === "Email/LinkedIn InMail"?
                      <>
                      <Button size="small" variant="outlined" startIcon={<ContentCopyIcon />} onClick={()=>{copyToClipboard("subject")}}>Copy Subject</Button>
                      <Button size="small" variant="outlined" startIcon={<ContentCopyIcon />} onClick={()=>{copyToClipboard("message")}}>Copy Message</Button>
                      </>
                      :followUpChannel === "WhatsApp/LinkedIn/Twitter DM etc."?
                      <>
                      <Button size="small" variant="outlined" startIcon={<ContentCopyIcon />} onClick={()=>{copyToClipboard("message")}}>Copy First Message</Button>
                      <Button size="small" variant="outlined" startIcon={<ContentCopyIcon />} onClick={()=>{copyToClipboard("script")}}>Copy Script</Button>
                      </>
                      :
                      <Button size="small" variant="outlined" startIcon={<ContentCopyIcon />} onClick={()=>{copyToClipboard("script")}}>Copy Script</Button>
                      }
                    </Stack>
                    
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" sx={{flexWrap: 'wrap'}}>
                      <Typography>🪄 Rewrite</Typography>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onRewrite("Make it formal")}>Formal</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onRewrite("Make it casual")}>Casual</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onRewrite("Make it humorous")}>Humorous</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onRewrite("Make it serious")}>Serious</Button>
                      <Stack direction="row" alignItems="center">
                        <TextField sx={{"& .MuiInputBase-input": {height: "30px", padding: "0 14px"}}} placeholder="Custom Instruction" value={instruction} onChange={e => setInstruction(e.target.value)} />
                        <Button size="small" variant="outlined" onClick={onRewrite}>Rewrite</Button>
                      </Stack>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" sx={{flexWrap: 'wrap'}}>
                      <Typography>Personalise using Buyer's</Typography>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onPersonalise(1)}>Social Media Post</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onPersonalise(2)}>Work & Expertise</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onPersonalise(3)}>Company/Industry News</Button>
                    </Stack>
                  </Stack>
                  :<></>
                }
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
    </Dialog>
  )
}