import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Typography, Divider} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { PERSONAS, STAGE_MAPPING } from "./../utils/constants"

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListIcon from '@mui/icons-material/List';

import AddPersona from "./AddPersona"
import AddCustomerProfile from "./AddCustomerProfile"
import CustomerProfileScore from "./CustomerProfileScore"

// this persona card was used under CustomerProfileContent card
// function AddPersonaCard(props){
//   return (
//     <Paper style={{marginTop: "8px", height: "80px", borderRadius: "8px", border: "1px", padding: "24px", borderStyle: "dashed"}}
//     sx={{':hover': {boxShadow: 10, cursor: "pointer"}}} onClick={props.addPersona}>
//       <div style={{marginTop: "0px"}}></div>
//       <Stack direction="row" alignItems="center" justifyContent="center">
//         <ControlPointIcon color="primary" fontSize="large"/>&nbsp;&nbsp;
//         <Typography display="inline"><b>Add New Persona</b></Typography>
//       </Stack>
//     </Paper>
//   )
// }

function AddPersonaCard(props){
  return (
    <Paper style={{borderRadius: "8px", border: "1px", padding: "24px", borderStyle: "dashed", height: 214, backgroundColor: "#fbfafe"}}
    sx={{':hover': {boxShadow: 10, cursor: "pointer"}}} onClick={props.addPersona} elevation={2}>
      <Stack direction="row" alignItems="center" justifyContent="center" alignContent="center" height= "100%">
        <ControlPointIcon color="primary" />&nbsp;&nbsp;
        <Typography display="inline" variant="h6">Add New Persona</Typography>
      </Stack>
    </Paper>
  )
}

export default function CustomerProfileContent(props){
  const [cpMode, setCpMode] = useState("view")
  const activeCustomerProfile = props.customerProfile
  let history = useHistory();

  const addPersona = () => {
    const c_id = props.match.params.c_id
    history.push('/icp/' + String(c_id) + '/persona/add_new');
  }

  return (
    <>
    {cpMode === "view"?
      <Grid container direction="row" spacing={2} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Paper style={{borderRadius: "8px", padding: "24px", position: "sticky", top: "20"}}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5">{activeCustomerProfile["name"]}</Typography>
                  <Chip size="small" label={activeCustomerProfile["stage"]} style={{background: 'linear-gradient(to right, #395af6, #19F4A6)', color: "white"}} />
                </Grid>
                <Grid item>
                  <IconButton onClick={() => setCpMode("edit_cp")}><EditIcon color="primary"/></IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography>{activeCustomerProfile["description"]}</Typography>
              </Grid>
              {/*<center><Avatar src={PERSONAS[0]} sx={{width: "100px", height: "100px"}} />
              </center>
              */}
              <Grid item xs={12}>
                <Divider sx={{mb: 2}} />
                <Typography><b>Problems</b></Typography>
                {activeCustomerProfile["problems"].map((y, ind) => {
                  return (
                    <Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16" /><Typography>{y}</Typography></Stack>
                  )
                })}
                <Divider sx={{mt: 2}} />
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Industries Explainer">
                  <Typography><b>Industries</b></Typography>
                </Tooltip>
                <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                  {activeCustomerProfile["industries"].map((x, ind) => {
                    return (
                      <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
                    )
                  })}
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Verticals Explainer">
                  <Typography><b>Verticals</b></Typography>
                </Tooltip>
                <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                  {activeCustomerProfile["verticals"].map((x, ind) => {
                    return (
                      <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
                    )
                  })}
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Company Size Explainer">
                  <Typography><b>Company Size</b></Typography>
                </Tooltip>
                <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                  {activeCustomerProfile["company_size"].map((x, ind) => {
                    return (
                      <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
                    )
                  })}
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Team Size Explainer">
                  <Typography><b>Team Size</b></Typography>
                </Tooltip>
                <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                  {activeCustomerProfile["team_size"].map((x, ind) => {
                    return (
                      <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
                    )
                  })}
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Geography Explainer">
                  <Typography><b>Geography</b></Typography>
                </Tooltip>
                <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                  {activeCustomerProfile["geographies"].map((x, ind) => {
                    return (
                      <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
                    )
                  })}
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Technical Buying Signals Explainer">
                  <Typography><b>Technical Buying Signals</b></Typography>
                </Tooltip>
                <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                  {activeCustomerProfile["buying_signals_tech"].map((x, ind) => {
                    return (
                      <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
                    )
                  })}
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Tooltip title="Non-technical Buying Signals Explainer">
                  <Typography><b>Non-technical Buying Signals</b></Typography>
                </Tooltip>
                <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                  {activeCustomerProfile["buying_signals_non_tech"].map((x, ind) => {
                    return (
                      <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
                    )
                  })}
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item xs={12} md={8} direction="row" alignContent="start" spacing={1}>
          <Grid item xs={12}>
            <CustomerProfileScore data={activeCustomerProfile}/>
          </Grid>
          <Grid item xs={12} sx={{mt:2}}>
            <Typography variant="h5">Buyer Personas</Typography>
          </Grid>
          <Grid item xs={6}>
            <AddPersonaCard addPersona={addPersona} />
          </Grid>
          {activeCustomerProfile["personas"].length > 0 && activeCustomerProfile["personas"].map((x, ind) => {
            return (
              <Grid item xs={6} key={ind}>
                <Paper style={{borderRadius: "8px", padding: "24px", maxHeight: 220, overflowY: "auto"}}>
                  <Grid container direction="row" spacing={3}>
                    <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                      <Grid item>
                      <Stack direction="row" alignItems="center">
                        <Avatar src={PERSONAS[x["avatar"]]} sx={{width: "70px", height: "70px"}} />
                        &nbsp;&nbsp;&nbsp;
                        <Typography variant="h6">{x["designation"]},&nbsp;{x["vertical"]}</Typography>
                      </Stack>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => history.push('/icp/' + String(props.match.params.c_id) + '/persona/' + String(x["_id"]["$oid"]))}>
                          <EditIcon color="primary"/>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="column">
                        <Typography><b>Value Props</b></Typography>
                        {x["value_props"].map((y, ind) => {
                          if(y!==""){
                            return (
                              <Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16" /><Typography>{y}</Typography></Stack>
                            )
                          }else return (<></>)
                        })}
                        {x["benefits"].map((y, ind) => {
                          if(y!==""){
                            return (
                              <Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16" /><Typography>{y}</Typography></Stack>
                            )
                          }else return (<></>)
                        })}
                      </Stack>
                    </Grid>
                    {/*
                    <Grid item xs={6} sx={{minHeight: "125"}}>
                      <Stack direction="column">
                        <Stack direction="row" alignItems="center">
                          <ListIcon color="primary" />
                          <Typography><b>Qualitative Value Props</b></Typography>
                        </Stack>
                        {x["value_props"].map((y, ind) => {
                          return (
                            <Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16" /><Typography>{y}</Typography></Stack>
                          )
                        })}
                      </Stack>
                    </Grid>
                    <Grid item xs={6} sx={{minHeight: "125"}}>
                      <Stack direction="column">
                        <Stack direction="row" alignItems="center">
                          <ListIcon color="primary" />
                          <Typography><b>Quantitative Value Props</b></Typography>
                        </Stack>
                        {x["benefits"].map((y, ind) => {
                          return (
                            <Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16" /><Typography>{y}</Typography></Stack>
                          )
                        })}
                      </Stack>
                    </Grid>
                    <Grid item xs={6} sx={{minHeight: "125"}}>
                      <Stack direction="row" alignItems="center">
                        <ListIcon color="primary"/>
                        <Typography><b>Objections</b></Typography>
                      </Stack>
                      {x["objections"].map((y, ind) => {
                        return (
                          <Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16"/><Typography>{y}</Typography></Stack>
                        )
                      })}
                    </Grid>
                    <Grid item xs={6} sx={{minHeight: "125"}}>
                      <Stack direction="row" alignItems="center">
                        <ListIcon color="primary" />
                        <Typography><b>Use Cases</b></Typography>
                      </Stack>
                      {x["usecases"].map((y, ind) => {
                        return (
                          <Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16"/><Typography>{y}</Typography></Stack>
                        )
                      })}
                    </Grid>
                    */}
                  </Grid>
                </Paper>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    :
    cpMode === "edit_cp"?
      <AddCustomerProfile mode="edit" data={activeCustomerProfile}/>
    :
    <></>
    }
    </>
  )
}
